<script>
import { defineAsyncComponent, h } from 'vue';

import FUNDS from '../data/fundsData';
import LitModal from './Base/LitModal.vue';
import LitTabs from './Base/Tabs/LitTabs.vue';
import LitTab from './Base/Tabs/LitTab.vue';

const FundsChart = defineAsyncComponent(() => import('@/js/components/FundsChart.vue'));
const LifeCycleStrategyDetail = defineAsyncComponent(() => import('@/js/components/LifeCycleStrategyDetail.vue'));

export default {
  components: {
    LitTabs,
    LitTab,
    LitModal,
    FundsChart,
    LifeCycleStrategyDetail,
  },

  props: {
    recommendedStrategies: {
      type: Array,
      default: () => [],
      required: true,
    },

    modelValue: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
  },

  emits: [
    'update:modelValue',
  ],

  data: () => ({
    showStrategyDetail: null,
  }),

  render () {
    const createTab = ({
      title,
      slug,
      content,
      label,
      labelType,
      labelIcon,
      dusk,
    }) => (
      h(
        LitTab,
        {
          title,
          label,
          'label-type': labelType,
          dusk,
          labelIcon,
          'data-slug': slug,
          'key': slug,
        },

        () => [
          h('p', [content.description.short]),
          h('button', {
            onClick: () => {
              this.showStrategyDetail = slug;
            },

            class: 'btn-simple',
          }, [content.detailLink]),
        ],
      ));

    const children = [];

    // Reset all fund labels first
    Object.values(FUNDS).forEach((fund) => {
      fund.label = null;
      fund.labelType = null;
      fund.labelIcon = null;
    });

    this.recommendedStrategies?.forEach((strategy) => {
      if (FUNDS[strategy.value] && strategy.isRecommended) {
        FUNDS[strategy.value].label = 'Doporučujeme';
        FUNDS[strategy.value].labelType = 'warning';
        FUNDS[strategy.value].labelIcon = 'tag-star_white';
      }

      children.push(createTab(FUNDS[strategy.value]));
    });

    const content = [
      h(LitTabs, {
        'returnAttribute': 'data-slug',
        'modelValue': this.modelValue,

        'onUpdate:modelValue': (event) => {
          this.$emit('update:modelValue', event);
        },
      }, () => children),
    ];

    const fundDetailBody = [];

    if (this.showStrategyDetail === 'lifeCycle') {
      fundDetailBody.push(
        h(LifeCycleStrategyDetail),
      );
    } else if (this.showStrategyDetail === 'reinvestment') {
      fundDetailBody.push(
        h('p', {
          innerHTML: FUNDS[this.showStrategyDetail]?.content?.description.long,
        }),
      );
    } else {
      fundDetailBody.push(
        h(FundsChart, {
          fundType: this.showStrategyDetail,
          class: 'mb-30',
        }),

        h('p', {
          innerHTML: FUNDS[this.showStrategyDetail]?.content?.description.long,
        }),
      );
    }

    if (this.showStrategyDetail !== null) {
      content.push(h(
        LitModal,
        {
          'modelValue': this.showStrategyDetail !== null,
          'max-width': 838,

          'onUpdate:modelValue': (show) => {
            if (show === false) {
              this.showStrategyDetail = null;
            }
          },
        },
        {
          header: () => h('h3', {
            class: 'text-left',
            innerHTML: FUNDS[this.showStrategyDetail].title,
          }),

          body: () => fundDetailBody,

          footer: () => h('div', {
            class: 'modal__buttons flex-end',
          }, [
            h('button', {
              class: 'btn btn-primary btn-outline',
              dusk: 'close-modal',
              onClick: () => {
                this.showStrategyDetail = null;
              },
              innerHTML: 'Zavřít',
            }),
          ]),

        },
      ));
    }

    return h('div', content);
  },
};
</script>
