import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import { state as onlineAgreementState } from './state';

import {
  measureAdobeAnalytics,
  validateStoreField,
  validateStoreFields,
} from '@/js/stores/utils';

import { calculateAge, canEnterRent, personalIdNumber } from '@/js/utils';
import gtm from '@/js/services/gtm';
import { EContractType } from '@/js/types/contract.ts';
import { router } from '@/js/router.ts';

export const useOnlineAgreementStore = defineStore('onlineAgreement', () => {
  const state = reactive(onlineAgreementState);

  const isChild = computed(() => state.contactInformation.contractFor.value === '1');
  const isBankIdEntry = computed(() => state.personalData.selectedEntryMethod.value === 'bankId');
  const isOnlineFinish = computed(() => state.contractSettings.finishMethod.value === 'online');
  const isOnlineJps = computed(() => state.contractSelection.type.value !== EContractType.Online);

  const participantsBirthDay = computed(() => {
    return personalIdNumber(state.personalData.personalIdNumber.value)?.birthDay;
  });

  const isPersonalDataFilledManually = computed(() => {
    return state.personalData.selectedEntryMethod.value === 'manually';
  });

  const clientAge = computed(() => {
    return participantsBirthDay.value ? calculateAge(participantsBirthDay.value.toISOString()) : null;
  });

  function resetPensionQuestionnaire () {
    const path = state.contributionAndStrategy;

    path.selectCustomStrategy.value = path.selectCustomStrategy.defaultValue;
    path.showQuestionnaire.value = path.showQuestionnaire.defaultValue;
    path.totalPoints.value = path.totalPoints.defaultValue;
    path.selectedStrategy.value = path.selectedStrategy.defaultValue;
    path.fundCombination.value = path.fundCombination.defaultValue;
    path.investmentRiskCategory.value = path.investmentRiskCategory.defaultValue;
    path.excludeConservativeFund.value = path.excludeConservativeFund.defaultValue;
    path.recommendedStrategies.value = path.recommendedStrategies.defaultValue;

    const questionFields = ['answer', 'openAnswer', 'category'];
    for (let i = 1; i <= 10; i++) {
      const questionKey = `question${i}` as keyof typeof path.questionnaire;
      const question = path.questionnaire[questionKey];

      questionFields.forEach((field) => {
        const questionField = question[field as keyof typeof question];
        questionField.value = questionField.defaultValue;
      });
    }
  }

  async function validateField ({
    contractUuid,
    fieldPath,
    pathToValidate,
    value,
    urlPath,
  }: {
    value: unknown
    pathToValidate: string | undefined
    contractUuid: string | string[]
    fieldPath: string
    urlPath: string
  }) {
    if (state.rehydrated.value === false) {
      console.warn('Skipping validation as store has not been hydrated yet');
      return;
    }
    try {
      await validateStoreField({
        contractUuid,
        fieldPath,
        // This is used for nominees array validation
        // where fieldPath is nominees.0.foo.value, but we need to trigger validation
        // for nominees.*.foo.value but reset errors for the nominees.0.foo.errors
        pathToValidate,
        throwOnErrors: true,
        value,
        state,
      });
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }

      if (e.response.status === 401) {
        await router.push({
          name: 'error',
        });
      }

      const { errors } = e.response.data;

      if (errors) {
        const keys = Object.keys(errors);

        // We want only the first one as we validate single field
        const [firstError] = errors[keys[0]];

        gtm.onFormFieldError(fieldPath, firstError);
      }
    } finally {
      if (!isOnlineJps.value) {
        measureAdobeAnalytics({
          state,
          action: 'ufFieldChanged',
          contractUuid,
          path: urlPath,
          fields: [
            { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
            { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
            { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
            { storePath: 'contactInformation.email.value', fieldName: 'email' },
            { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
            { storePath: 'consents.marketingPurposes.value', fieldName: 'marketingagreement' },
          ],
        });
      }
    }
  }

  async function validateFields ({
    contractUuid,
    fields,
    throwOnErrors,
    fieldPaths,
    documentsSent = false,
  }: {
    contractUuid: string | string[]
    fields?: { path: string, value?: unknown, pathToValidate?: string }[]
    fieldPaths?: string | string[]
    throwOnErrors: boolean
    documentsSent?: boolean
  }) {
    return await validateStoreFields(state, {
      contractUuid,
      fields,
      throwOnErrors,
      fieldPaths,
      documentsSent,
    });
  }

  function updatePreferConservativeFund () {
    state.contributionAndStrategy.preferConservativeFund.value = canEnterRent(participantsBirthDay.value);
  }

  return {
    ...toRefs(state),
    isChild,
    isBankIdEntry,
    isOnlineFinish,
    clientAge,
    isPersonalDataFilledManually,
    participantsBirthDay,
    isOnlineJps,
    resetPensionQuestionnaire,
    validateField,
    validateFields,
    updatePreferConservativeFund,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOnlineAgreementStore, import.meta.hot));
}
