<template>
  <div>
    <LossCalculator
      :allow-buttons="true"
      :hide-transfer-tiles="true"
      :links="{
        portal: 'https://online.csob-penze.cz/',
        registration: 'https://online.csob-penze.cz/request/registration',
        evaluation: 'https://csob-penze.cz/pro-stavajici-klienty/#zhodnoceni-fondu',
        dps: 'https://csob-penze.cz/pro-stavajici-klienty/penzijni-sporeni/',
        branches: 'https://csob-penze.cz/pobocky/',
        phone: 'tel:00420495800600',
        pensionPlans: 'https://csob-penze.cz/dokumenty/penzijni-plany/',
        dpsForm: 'https://csob-penze.cz/dokumenty/formulare#DPS',
        trfForm: 'https://csob-penze.cz/dokumenty/formulare#TRF'
      }"
      @submitted="submitStep"
      @submit-errors="onError"
    />
  </div>
</template>

<script setup lang="ts">
import { LossCalculator } from '@csob-penze/csob-penze-design';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { signatures } from '@/js/api';
import { create } from '@/js/api/contracts';
import { useGeneralStore, useTerminationStore } from '@/js/stores';
import { scrollToError } from '@/js/utils';

import '@csob-penze/csob-penze-design/dist/csob-penze-design.css';

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const { terminationSettings, beforeStart } = storeToRefs(store);
const { validateFields } = store;
const generalStore = useGeneralStore();

const isOldAgePensionEnabled = computed({
  get () {
    return terminationSettings.value.oldAgePension.isEnabled.value;
  },
  set (value) {
    terminationSettings.value.oldAgePension.isEnabled.value = value;
  },
});

const loading = ref(false);

function onError () {
  scrollToError();
}

async function submitStep (data: any) {
  if (loading.value) {
    return;
  }

  try {
    loading.value = true;
    const response = await create('termination');

    const signature = [
      {
        signer: 'distributor',
        signMethod: route.query.bankId === 'true' ? 'digital' : 'offline',
      },
      {
        signer: 'client',
        signMethod: route.query.bankId === 'true' ? 'bankId' : 'offline',
      },
    ];

    await signatures.create(response.contract.uuid, JSON.stringify(signature));

    if (!data.oneTimeSettlement.isEnabled) {
      terminationSettings.value.paymentOption.value = 'smartMoney';
    }

    if (data.isAdditionalPensionSaving) {
      isOldAgePensionEnabled.value = data.isMonthlyPensionAvailable;
    } else {
      isOldAgePensionEnabled.value = false;
    }

    if (isOldAgePensionEnabled.value) {
      terminationSettings.value.paymentOption.value = 'oldAgePensionLongTerm';
    }

    store.$patch((state) => {
      state.currentContractInformation.totalAmount.value = data.totalAmount;
      state.currentContractInformation.contractNumber.value = data.contractNumber;
      state.currentContractInformation.isAdditionalPensionSaving.value = data.isAdditionalPensionSaving;
      state.terminationSettings.oneTimeSettlement.isEnabled.value = data.oneTimeSettlement.isEnabled;
      state.terminationSettings.withTransfer.value = false;
    });

    await validateFields({
      contractUuid: response.contract.uuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    await router.push({
      name: 'termination.beforeStart',
      params: { contractUuid: response.contract.uuid },
      query: { bankId: route.query.bankId },
    });
  } catch (e: any) {
    const status = e?.response?.status;
    const conflictingUuid = e?.response?.headers['x-conflicts-with'];

    if (status === 409 && conflictingUuid) {
      console.warn('Redirecting to ongoing contract', conflictingUuid);

      await router.push({
        name: 'termination.beforeStart',
        params: { contractUuid: conflictingUuid },
        query: route.query,
      });
    } else {
      await router.push({
        name: 'error',
      });
    }
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  beforeStart.value.isTiedAgent.value = generalStore.isTiedAgent.value;
  beforeStart.value.distributionName.value = generalStore.distribution.value;
});
</script>
