<template>
  <div dusk="thank-you">
    <JpsSignatureStepper :current-step="1" />

    <div v-if="contractIsSigned" class="container--sm text-center my-30">
      <div>
        <TheHeading>Smlouva je úspěšně podepsána</TheHeading>

        <p>
          Klientovi jsme na e-mail <strong>{{ contactInformation.email.value }}</strong> poslali
          odkaz pro podepsání výpovědi skrze Bank iD.
        </p>

        <p>Až klient podepíše, přijde vám potvrzení e-mailem.</p>
      </div>

      <div
        v-if="store.beforeStart.isTiedAgent.value"
        class="container--sm text-center my-30"
      >
        <hr class="py-20 mt-20">

        <h3
          class="text-center"
        >
          A zde jsou údaje klienta, pro kterého byla smlouva uzavřena
        </h3>

        <table class="recapitulation-table mb-16">
          <tbody>
            <tr>
              <td>Číslo smlouvy</td>
              <td
                colspan="2"
              >
                {{ contractNumber }}
              </td>
            </tr>
            <tr>
              <td>Rodné číslo klienta</td>
              <td
                colspan="2"
              >
                {{ store.personalData.personalIdNumber.value }}
              </td>
            </tr>
            <tr>
              <td>Jméno a přijmení klienta</td>
              <td
                colspan="2"
              >
                {{ store.personalData.firstName.value }} {{ store.personalData.lastName.value }}
              </td>
            </tr>
            <tr>
              <td>Frekvence placení</td>
              <td
                colspan="2"
              >
                Měsíčně
              </td>
            </tr>
            <tr>
              <td>Částka, kterou si bude klient platit</td>
              <td
                colspan="2"
              >
                {{ store.contributionAndStrategy.contributionAmount.value }} Kč
              </td>
            </tr>

            <tr>
              <td>Typ úkonu</td>
              <td
                colspan="2"
              >
                2 - Výpověď doplňkového penzijního spoření se žádostí o&nbsp;převod prostředků k&nbsp;ČSOB&nbsp;PS
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="!contractIsSigned && !fileNotFound">
      <div class="flex flex-center mb-20 flip text-center my-20">
        <img
          :src="getIconUrl('icon-loader_blue')"
          alt="Načítání..."
          class="spin"
        >
      </div>
      <h3 class="text-center">
        Dochází ke zpracování nové smlouvy
      </h3>
    </div>

    <div v-if="fileNotFound">
      <div class="mb-30 chart-img my-20 text-center flex-center">
        <img
          :src="getIconUrl('icon-state_danger')"
          alt="chyba-500"
        >
      </div>
      <h3 class="text-danger">
        Došlo k chybě při podpisu nové smlouvy<span>, prosím uzavřete ji znovu.</span>
      </h3>
    </div>

    <div
      class="container--sm my-30 separator"
    />

    <div class="container--sm text-center my-30">
      <LitButton
        variant="outline"
        :to="{ name: 'distribution.home' }"
      >
        <span>
          Zpět na hlavní rozcestník
        </span>
      </LitButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { draftTokens } from '@/js/api';
import { show } from '@/js/api/contracts.ts';

import LitButton from '@/js/components/Base/LitButton.vue';
import JpsSignatureStepper from '@/js/components/Steppers/JpsSignatureStepper.vue';
import TheHeading from '@/js/components/TheHeading.vue';
import { useJpsStore } from '@/js/stores';
import { resetState } from '@/js/stores/utils';
import { getIconUrl, waitForSignedPdf } from '@/js/utils.ts';

const store = useJpsStore();
const { contactInformation } = storeToRefs(store);

const route = useRoute();
const contractNumber = ref(null);
const contractIsSigned = ref(false);
const fileNotFound = ref(false);
const maxFileAttemptTries = 6;

onBeforeRouteLeave ((to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  clearLocalContractData();

  const disallowedPaths = [
    'jsp.contractDocumentsAndConsents',
  ];

  if (to.name && disallowedPaths.includes(to.name as string)) {
    next({ name: 'distribution.home' });
  } else {
    next();
  }
});

onMounted(async () => {
  const { contractUuid } = route.params;
  try {
    await waitForSignedPdf(contractUuid as string, 'jps', maxFileAttemptTries);
    contractIsSigned.value = true;

    if (!await existsDraftToken('bankId')) {
      await sendDraftToken('bankId');
    }
  } catch (e) {
    console.warn(e);

    contractIsSigned.value = false;
    fileNotFound.value = true;
  }

  const { data } = await show(contractUuid as string, 'jps');
  contractNumber.value = data.contract_number;
});

function clearLocalContractData () {
  resetState(store.$state);
}

async function existsDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  const { data } = await draftTokens.show(contractUuid as string, signMethod);

  return data.draftToken !== null;
}

async function sendDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    const draftTokenExists = await existsDraftToken(signMethod);
    if (!draftTokenExists) {
      await draftTokens.create(contractUuid as string, signMethod);
    } else {
      console.warn('Draft token already created');
    }
  } catch (e) {
    console.warn('Failed creating draft token', e);
  }
}
</script>

<style lang="scss" scoped>
@import '@/sass/tools/mixins';
@import '@/sass/tools/variables';
@import '@/sass/tools/functions';

.bg-green {
  background-color: getColor(bg-green);
  overflow-y: hidden;
}

h3 {
  text-align: center;

  @include media(min, $md) {
    text-align: left;
  }
}

.text-center {
  text-align: center;
}

.short {
  width: 100%;

  @include media(min, $md) {
    width: 50%;
  }
}

li {
  list-style-image: url('@/images/icons/dot.svg');
}

.cta {
  background: getColor(cta);
  display: flex;
  padding: 15px;

  &-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    line-height: 1.45;
    width: 100%;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    margin-right: 15px;

    img {
      width: 25px;
    }
  }
}

.loading-icon {
  transform: rotateY(180deg);

  svg {
    height: 50px;
    width: 50px;
    transform: rotateY(180deg);
  }
}

.separator {
  width: auto;
  height: 1px;
  background-color: getColor(light-grey);
}

.text-danger {
  text-align: center;
}
</style>
