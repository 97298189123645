interface Strategy {
  value: string
  isRecommended: boolean
}

type Category = 'A' | 'B' | 'C' | 'D' | 'E';

export const NEW_CONTRACT_STRATEGIES = {
  A: {
    strategies: [
      { value: 'pension', isRecommended: true },
      { value: 'conservative', isRecommended: false },
    ],
    description: 'Upřednostňujete jistotu výnosu před jeho výší nebo nemáte s investováním zkušenosti.',
  },
  B: {
    strategies: [
      { value: 'lifeCycle', isRecommended: true },
      { value: 'balanced', isRecommended: false },
    ],
    description: 'Chcete vyšší výnos a nevadí Vám vyšší riziko kolísání hodnoty investice než u konzervativních fondů.',
  },
  C: {
    strategies: [
      { value: 'dynamic', isRecommended: true },
      { value: 'esg', isRecommended: false },
    ],
    description: 'Chcete maximální růst a nevadí Vám vyšší riziko kolísání hodnoty investice.',
  },
  D: {
    strategies: [
      { value: 'conservative', isRecommended: false },
      { value: 'pension', isRecommended: false },
    ],
    description: 'Vzhledem k tomu, že jste zvolil/la krátký investiční horizont, nabízíme Vám konzervativní typy fondů.',
  },
  E: {
    strategies: [
      { value: 'conservative', isRecommended: false },
    ],
    description: 'Upřednostňujete jistotu výnosu před jeho výší. Plánujete spořit krátkou dobu.',
  },
} as const;

export const TRANSFER_STRATEGIES = {
  A: {
    strategies: [
      { value: 'pension', isRecommended: true },
      { value: 'conservative', isRecommended: false },
    ],
    description: 'Upřednostňujete jistotu výnosu před jeho výší nebo nemáte s investováním zkušenosti.',
  },
  B: {
    strategies: [
      { value: 'reinvestment', isRecommended: true },
      { value: 'balanced', isRecommended: false },
    ],
    description: 'Chcete vyšší výnos a nevadí vám vyšší riziko kolísání hodnoty investice než u konzervativních fondů.',
  },
  C: {
    strategies: [
      { value: 'reinvestment', isRecommended: true },
      { value: 'dynamic', isRecommended: false },
    ],
    description: 'Chcete maximální růst a nevadí vám vyšší riziko kolísání hodnoty investice.',
  },
  D: {
    strategies: [
      { value: 'conservative', isRecommended: false },
      { value: 'pension', isRecommended: false },
    ],
    description: 'Vzhledem k tomu, že jste zvolil/la krátký investiční horizont, nabízíme Vám konzervativní typy fondů.',
  },
  E: {
    strategies: [
      { value: 'conservative', isRecommended: false },
    ],
    description: 'Upřednostňujete jistotu výnosu před jeho výší. Plánujete spořit krátkou dobu.',
  },
} as const;

export const getStrategiesForCategory = (
  category: Category,
  isTransfer = false,
): Strategy[] => {
  const strategies = isTransfer ? TRANSFER_STRATEGIES : NEW_CONTRACT_STRATEGIES;

  return [...(strategies[category].strategies ?? [])];
};

export const getCategoryDescription = (
  category: Category,
  isTransfer = false,
): string => {
  const strategies = isTransfer ? TRANSFER_STRATEGIES : NEW_CONTRACT_STRATEGIES;

  return strategies[category].description ?? null;
};
