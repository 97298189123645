<script lang="ts" setup>
import { nextTick, onMounted, ref, useAttrs, watch } from 'vue';

interface Props {
  errors?: string[]
  id?: string
  name?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  rows?: number
  maxRows?: number
  autoresize?: boolean
  autofocus?: boolean
  resize?: boolean
  maxLength?: number | null
}

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
  required: false,
  disabled: false,
  rows: 6,
  maxRows: 0,
  autoresize: false,
  autofocus: false,
  resize: false,
  maxLength: null,
});

const emit = defineEmits(['blur']);

const slots = defineSlots<{
  default?: () => any
  label?: () => any
}>();

const modelValue = defineModel<string | number>({
  required: true,
  default: '',
});

const { class: attrsClass, ...restAttrs } = useAttrs();

const textarea = ref<HTMLTextAreaElement | null>(null);

const autoResize = () => {
  if (props.autoresize && textarea.value) {
    textarea.value.rows = props.rows;

    const element = textarea.value as HTMLTextAreaElement;
    const styles = window.getComputedStyle(element);
    const paddingTop = Number.parseInt(styles.paddingTop, 10);
    const paddingBottom = Number.parseInt(styles.paddingBottom, 10);

    const padding = paddingTop + paddingBottom;
    const lineHeight = styles.lineHeight === 'normal' ? 14 : Number.parseInt(styles.lineHeight, 10);
    const { scrollHeight } = element;
    const newRows = Math.floor((scrollHeight - padding) / lineHeight);

    if (newRows > props.rows) {
      element.rows = props.maxRows ? Math.min(newRows, props.maxRows) : newRows;
    }
  }
};

const autoFocus = () => {
  if (props.autofocus) {
    textarea.value?.focus();
  }
};

const onInput = (e: Event) => {
  autoResize();

  modelValue.value = (e.target as HTMLInputElement).value;
};

const onChange = (e: Event) => {
  modelValue.value = (e.target as HTMLInputElement).value;
};

const onBlur = (e: FocusEvent) => {
  emit('blur', e);
};

watch(modelValue, () => {
  nextTick(autoResize);
});

onMounted(() => {
  setTimeout(() => {
    autoResize();
    autoFocus();
  }, 100);
});
</script>

<template>
  <div class="field" :class="attrsClass">
    <label
      v-if="slots.label"
      :for="name"
    >
      <slot name="label" />

      <span
        v-if="required"
        class="required"
      > *</span>
    </label>

    <div class="control__wrapper">
      <div
        class="control"
        :class="errors.length > 0 ? 'control--error' : ''"
      >
        <textarea
          ref="textarea"
          :value="modelValue"
          :name
          :rows
          :placeholder
          :required
          :maxlength="maxLength || undefined"
          :disabled
          v-bind="restAttrs"
          :resize
          :style="{
            resize: props.resize ? undefined : 'none'
          }"
          @input="onInput"
          @change="onChange"
          @blur="onBlur"
        />
      </div>

      <div
        v-if="maxLength && typeof modelValue === 'string'"
        class="control__max-length"
      >
        {{ modelValue.length }} / {{ maxLength }}
      </div>

      <div class="field-info">
        <small
          v-for="error in errors"
          :key="error"
          class="control--error error"
        >
          {{ error }}
        </small>
      </div>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

%formStyle {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: $root-small;
  color: getColor(default);

  &::placeholder {
    color: getColor(light-blue);
    font-weight: $normal;
  }
}

textarea {
  @extend %formStyle;

  padding: .75em;
  pointer-events: all;

  &:focus {
    outline: none;
  }

  &:last-of-type:not(:only-of-type) {
    margin-right: .063em;
  }
}

label {
  margin-bottom: 5px;
  display: block;
  font-weight: $normal;

  @include media(min, $sm) {
    flex-basis: 177px;
    margin-right: 10px;
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
    flex-shrink: 0;
  }
}

.field {
  display: flex;
  flex-flow: column;

  @include media(min, $sm) {
    flex-flow: row;
  }

  &-info {
    display: flex;
    flex-flow: column;
    max-width: 330px;

    &--m {
      @include media(min, $sm) {
        margin-left: 187px;
      }
    }
  }
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: getColor(bg);
  border-radius: 3px;
  min-height: $input-height;
  border: 1px solid getColor(light-blue);
  position: relative;
  max-width: 100%;
  flex-basis: 100%;
  width: 100%;

  &--error {
    border-color: getColor(danger, 1);
  }

  &--warning {
    border-color: getColor(warning, 1);
  }

  &.disabled {
    opacity: .5;
    color: #8397b3;
  }

  &:focus-within {
    border-color: getColor(blue);
  }

  &__max-length {
    display: block;
    padding-top: 5px;
    font-size: $root-small;
    color: rgba(0, 66, 121, .5);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex-shrink: 0;

    @include media(min, $sm) {
      flex-basis: 360px;
    }

    &.control {
      &--xs {
        .control {
          max-width: 91px;
        }
      }

      &--sm {
        .control {
          max-width: 220px;

          @include media(min, $sm) {
            max-width: 177px;
          }
        }

        @include media(min, $sm) {
          flex-basis: 207px;
        }
      }

      &--md {
        .control {
          max-width: 320px;
        }
      }
    }
  }
}

.error {
  display: block;
  padding: .125em 0;
  margin-top: .25em;
  color: getColor(danger, 1);
}
</style>
