<script lang="ts" setup>
import type { ComputedRef } from 'vue';

import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import ContributionSelection from '@/js/components/ContributionSelection.vue';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';
import MissingChangeWarningModal from '@/js/components/Modals/MissingChangeWarningModal.vue';
import ChangeRequestStepper from '@/js/components/Steppers/ChangeRequestStepper.vue';
import { useDateRange } from '@/js/composables/useDateRange.ts';
import gtm from '@/js/services/gtm';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import { scrollToError } from '@/js/utils.ts';
import StepButtons from '@/js/views/common/StepButtons.vue';

import QuestionnaireForm from '@/js/components/QuestionnaireForm.vue';
import QuestionnaireRadioGroup from '@/js/components/QuestionnaireRadioGroup.vue';

import availableAnswers from '@/js/data/financialQuestionnaire.json' with { type: 'json' };
import type { Question } from '@/js/types/questionnaire';
import { useQuestionnaire } from '@/js/composables/useQuestionnaire';

const route = useRoute();
const router = useRouter();

const store = useChangeRequestStore();
const {
  validateField,
  validateFields,
} = store;
const {
  contractSettings,
  isDPS,
  strategy,
} = storeToRefs(store);

const {
  questions,
  resetAnswer,
  isQuestionVisible,
  handleMultipleChoice,
  handleSingleChoice,
  forceKoAfterQuestion2,
} = useQuestionnaire(availableAnswers.questions, contractSettings.value.contribution.questionnaire);

forceKoAfterQuestion2.value = true;

const validating = ref(false);
const showFormsOfferModal = ref(false);
const displayChangeCheckModal = ref(false);

const taxOptimizationOptions = shallowRef([
  { value: true, label: 'Účastník chce sjednat Program daňové optimalizace' },
  { value: false, label: 'Účastník chce zrušit Program daňové optimalizace' },
]);

const employerActionOptions = shallowRef([
  { value: 'add', label: 'Přidat zaměstnavatele' },
  { value: 'change', label: 'Upravit zaměstnavatele' },
  { value: 'remove', label: 'Odstranit zaměstnavatele' },
]);

const taxOptimization: ComputedRef<boolean | null> = computed(() => {
  return contractSettings.value.taxOptimization.value.value;
});

const taxOptimizationChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.taxOptimizationChangeRequired.value;
});

const contribution = computed(() => {
  return contractSettings.value.contribution.amount.value;
});

const contributionChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.contributionChangeRequired.value;
});

const employerAction = computed(() => {
  return contractSettings.value.employer.action.value;
});

const employerChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.employerChangeRequired.value;
});

const getQuestionErrors = (questionId: number) => {
  const questionnaireKey = `question${questionId}` as keyof typeof contractSettings.value.contribution.questionnaire;

  return contractSettings.value.contribution.questionnaire[questionnaireKey].answer.errors;
};

const nothingChanged = computed(() => {
  return contractSettings.value.contributionChangeRequired.value === false
    && contractSettings.value.taxOptimizationChangeRequired.value === false
    && contractSettings.value.employerChangeRequired.value === false;
});

watch(taxOptimization, (newVal) => {
  if (newVal !== null) {
    contractSettings.value.taxOptimizationChangeRequired.value = true;
  }
});

watch(taxOptimizationChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.taxOptimization.value.value = null;
  }
});

watch(contribution, (newVal) => {
  if (newVal !== null && newVal !== 0) {
    contractSettings.value.contributionChangeRequired.value = true;
  }
});

watch(contributionChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.contribution.amount.value = null;
    contractSettings.value.contribution.group.value = '';
    contractSettings.value.contribution.grantedPension.value = false;

    resetAnswer(1, (questionId, _answer) => {
      const questionnaire = contractSettings.value.contribution.questionnaire;
      const questionKey = `question${questionId}` as keyof typeof questionnaire;
      const questionAnswer = questionnaire[questionKey];

      questionAnswer.answer.value = null;
      questionAnswer.openAnswer.value = '';
      questionAnswer.category.value = null;
    });
  }
});

watch(employerAction, (newVal) => {
  if (newVal !== null) {
    contractSettings.value.employerChangeRequired.value = true;
  }
});

watch(employerChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.employer.action.value = null;
    contractSettings.value.employer.name.value = null;
    contractSettings.value.employer.vat.value = null;
  }
});

function setContributionEffectiveDate () {
  if (contributionChangeRequired.value) {
    contractSettings.value.contribution.effectiveDate.value = contractSettings.value.taxOptimization.effectiveDate.value;
  }
}

function setAnswer (question: Question, answer: string | string[] | null) {
  const questionnaire = contractSettings.value.contribution.questionnaire;
  const questionKey = `question${question.id}` as keyof typeof questionnaire;
  const questionAnswer = questionnaire[questionKey];

  let result;

  if (question.type === 'single') {
    result = handleSingleChoice(question, answer as string | null);
  } else {
    result = handleMultipleChoice(
      question,
      answer as string,
      questionAnswer.answer.value as string[] | null,
    );
  }

  if (result.answer !== undefined) {
    questionAnswer.answer.value = result.answer;
  }

  if (result.category !== undefined) {
    questionAnswer.category.value = result.category;
  }

  // Reset open answer if needed
  if (result.answer === null || (Array.isArray(result.answer) && result.answer.length === 0)) {
    questionAnswer.openAnswer.value = '';
  }

  // Reset strategy selection when answers change
  strategy.value.selectedStrategy.value = null;
  strategy.value.selectCustomStrategy.value = false;
}

function setOpenAnswer (question: Question, answer: string | number) {
  const questionnaire = contractSettings.value.contribution.questionnaire;
  const questionKey = `question${question.id}` as keyof typeof questionnaire;
  const questionAnswer = questionnaire[questionKey];

  questionAnswer.openAnswer.value = String(answer);
}

const handleAnswerUpdate = (question: Question, answer: string | string[] | null) => {
  setAnswer(question, answer);
};

const handleOpenAnswerUpdate = (question: Question, answer: string | number) => {
  setOpenAnswer(question, answer);
};

async function checkSubmission () {
  if (nothingChanged.value) {
    displayChangeCheckModal.value = true;
  } else {
    await handleSubmit();
  }
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  setContributionEffectiveDate();

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'contractSettings.contribution.amount',
        'contractSettings.contribution.effectiveDate',
        'contractSettings.taxOptimization.value',
        'contractSettings.taxOptimization.effectiveDate',
        'contractSettings.employer.action',
        'contractSettings.employer.effectiveDate',
        'contractSettings.employer.vat',
        'contractSettings.employer.name',
        'contractSettings.employer.effectiveDate',
        'contractSettings.contribution.questionnaire.question1.answer',
        'contractSettings.contribution.questionnaire.question2.answer',
      ],
    });

    await router.push({
      name: 'changeRequest.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zmena-smlouvy');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation RecapitulationError: ${e}`);
  } finally {
    validating.value = false;
  }
}
</script>

<template>
  <div>
    <ChangeRequestStepper current-step="contractSettings" />

    <div class="container--sm mt-20">
      <div class="flex flex-between">
        <h3 dusk="contribution-heading" class="mt-10">
          Měsíční příspěvek
        </h3>

        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.contributionChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.contribution.amount"
        class="mb-40"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <ContributionSelection
          v-model:contribution-group="contractSettings.contribution.group.value"
          class="mb-30"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :is-for-distributor="true"
          :is-granted-pension="contractSettings.contribution.grantedPension.value"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        class="mb-40"
        field-path="contractSettings.contribution.grantedPension"
        :default-value="false"
        :debounce="0"
        :validate-on-input="true"
        @validate="validateField"
      >
        <LitCheckbox
          class="mb-20"
          dusk="granted-pension"
          tooltip="Od 1. 7. 2024 nemá klient, kterému již byl přiznám starobní důchod, nárok na státní příspěvek. Daňový odpočet lze uplatnit již od 1 Kč zaplaceného vlastního příspěvku."
          :show-tooltip="true"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        >
          Klientovi již byl přiznán starobní důchod
        </LitCheckbox>
      </ServerValidatedFormField>

      <div
        v-show="contractSettings.contributionChangeRequired.value && isDPS"
        class="mb-20"
      >
        <QuestionnaireForm
          :questions="(questions as Question[])"
          :is-question-visible="isQuestionVisible"
          @update:answer="handleAnswerUpdate"
          @update:open-answer="handleOpenAnswerUpdate"
        >
          <template #singleOption="{ question, updateAnswer }">
            <ServerValidatedFormField
              v-slot="{ value, errors, input }"
              namespace="changeRequest"
              :default-value="question.answer"
              :field-path="`contractSettings.contribution.questionnaire.question${question.id}.answer`"
              :validate-on-input="true"
              @validate="validateField"
            >
              <QuestionnaireRadioGroup
                v-if="question.viewStyle && question.viewStyle === 'radioGroup'"
                :model-value="typeof question.answer === 'string' ? question.answer : ''"
                :options="(question.options as any)"
                :error="errors.length > 0 ? errors[0] : ''"
                @update:model-value="updateAnswer(question, $event); input($event)"
              />
              <LitRadio
                v-else
                :name="`question${question.id}Options`"
                :options="question.options"
                :model-value="value"
                :error="errors.length > 0 ? errors[0] : ''"
                @update:model-value="updateAnswer(question, $event); input($event)"
              />
            </ServerValidatedFormField>
          </template>

          <template #multipleOption="{ question, option, updateAnswer, disabled }">
            <ServerValidatedFormField
              v-slot="{ errors, input }"
              namespace="changeRequest"
              :default-value="question.answer"
              :field-path="`contractSettings.contribution.questionnaire.question${question.id}`"
              :validate-on-input="true"
              @validate="validateField"
            >
              <LitCheckbox
                :label="option.label"
                :error="errors.length > 0 ? errors[0] : ''"
                :model-value="question.answer?.includes(option.value)"
                rounded
                :is-disabled="disabled"
                @update:model-value="updateAnswer(question, option.value); input($event)"
              >
                {{ option.label }}
              </LitCheckbox>
            </ServerValidatedFormField>
          </template>

          <template #additionalContent="{ question }">
            <div
              v-if="getQuestionErrors(question.id).length > 0"
              class="text-danger pt-10"
            >
              {{ getQuestionErrors(question.id)[0] }}
            </div>
          </template>
        </QuestionnaireForm>
      </div>

      <div class="flex flex-between">
        <h3>Program daňové optimalizace</h3>
        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.taxOptimizationChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.taxOptimization.value"
        :debounce="0"
        @validate="validateField"
      >
        <LitRadio
          class="mb-20"
          dusk="tax-optimalization"
          name="tax-optimalization"
          :options="taxOptimizationOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.taxOptimization.effectiveDate"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <LitSelect
          class="mb-20 mt-30"
          dusk="optimalization-start-date"
          label="Datum účinnosti měsíčního příspěvku a Programu daňové optimalizace"
          :options="useDateRange(3)"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <div class="flex flex-between">
        <h3>Příspěvek zaměstnavatele</h3>
        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.employerChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.employer.action"
        :debounce="0"
        @validate="validateField"
      >
        <LitRadio
          class="mb-20"
          dusk="employer-change"
          name="employer-change"
          :options="employerActionOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <div v-if="contractSettings.employerChangeRequired.value">
        <div v-if="contractSettings.employer.action.value !== 'remove'">
          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="changeRequest"
            field-path="contractSettings.employer.name"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              dusk="employer-name"
              name="nameOfEmployer"
              label="Název zaměstnavatele"
              placeholder="Např. AAA Auto, a.s."
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="changeRequest"
            field-path="contractSettings.employer.vat"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="employerId"
              dusk="employer-id"
              label="IČ zaměstnavatele"
              placeholder="Např. 95874375"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            >
              <template #actions>
                <div class="actions-group">
                  <a
                    href="https://ares.gov.cz/ekonomicke-subjekty"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vyhledat IČO v obchodním rejstříku
                  </a>
                </div>
              </template>
            </LitInput>
          </ServerValidatedFormField>
        </div>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="changeRequest"
          field-path="contractSettings.employer.effectiveDate"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitSelect
            class="mb-20 mt-30"
            dusk="employer-start-date"
            label="Datum účinnosti úpravy příspěvku zaměstnavatele"
            :options="useDateRange(3)"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>
      </div>

      <hr class="separator my-30">

      <div class="flex flex-between">
        <StepButtons
          namespace="changeRequest"
          previous-step="clientData"
          @submit="checkSubmission"
        />
      </div>

      <MissingChangeWarningModal
        v-model="displayChangeCheckModal"
        @close="displayChangeCheckModal = false"
      />

      <ChangeRequestFormsOfferModal
        v-model="showFormsOfferModal"
        :forms="store.changeSettings.involvedForms.value"
        @close="showFormsOfferModal = false"
        @finish="handleSubmit"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.heading_tooltip {
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}
</style>
