<script setup lang="ts">
import LitButton from '@/js/components/Base/LitButton.vue';
import LitModal from '@/js/components/Base/LitModal.vue';

defineEmits(['close']);

const model = defineModel({ type: Boolean, default: false });
</script>

<template>
  <LitModal
    v-model="model"
    :max-width="480"
  >
    <template #header>
      <h3>
        Vypadá to, že nedošlo k žádné úpravě
      </h3>
    </template>

    <template #body>
      <p class="text-center">
        Prosím upravte alespoň jeden údaj (měsíční příspěvek, program daňové optimalizace, příspěvek zaměstnavatele)
      </p>
    </template>

    <template #footer>
      <div class="flex flex-center content-center">
        <LitButton
          size="sm"
          @click="$emit('close', $event)"
        >
          Rozumím
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>
