<template>
  <div>
    <LitInput
      class="control__number"
      type="tel"
      :model-value="modelValue"
      :errors="errors"
      :label="label"
      :show-append="true"
      :dusk="dusk"
      :min="min"
      :tooltip-position="tooltipPosition"
      :show-tooltip="showTooltip"
      :setter="numberSetter"
      @update:model-value="$emit('update:modelValue', $event)"
      @blur="$emit('blur', $event)"
    >
      <template #append>
        <span class="unit unit--dark">Kč</span>
      </template>

      <template #number-actions>
        <div class="number-actions">
          <button
            class="number-actions__btn"
            dusk="add-button"
            @click="increaseAmount(modelValue.toString())"
          >
            +
          </button>

          <button
            class="number-actions__btn"
            dusk="reduce-button"
            @click="decreaseAmount(modelValue.toString())"
          >
            -
          </button>
        </div>
      </template>
    </LitInput>
  </div>
</template>

<script lang="ts">
import LitInput from './Base/LitInput.vue';

export default {
  components: {
    LitInput,
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    stepAmount: {
      type: Number,
      default: 1000,
    },

    errors: {
      type: Array,
      required: false,
      default: () => ([]),
    },

    dusk: {
      type: String,
      default: '',
    },

    min: {
      type: Number,
      required: false,
      default: null,
    },

    max: {
      type: Number,
      required: false,
      default: null,
    },

    tooltipPosition: {
      type: String,
      default: 'top',
    },

    showTooltip: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:modelValue',
    'blur',
  ],

  computed: {
    amount: {
      get () {
        return this.modelValue;
      },

      set (val: string | number) {
        this.$emit('update:modelValue', val);
      },
    },
  },

  methods: {
    setPlainAmount (amount: string) {
      return amount.replace(/\s/g, '');
    },

    increaseAmount (amount: string) {
      this.amount = new Intl.NumberFormat('cs-CZ')
        .format(Number(this.setPlainAmount(amount)) + this.stepAmount);
    },

    decreaseAmount (amount: string) {
      this.amount = new Intl.NumberFormat('cs-CZ')
        .format(Number(this.setPlainAmount(amount)) - this.stepAmount);
    },

    numberSetter (amount: string) {
      const plain = this.setPlainAmount(amount);

      if (plain.length < 4) {
        return plain;
      }

      const formatted = new Intl.NumberFormat('cs-CZ').format(Number(plain));

      if (formatted === 'NaN') {
        return plain;
      }

      return formatted;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.control__number {
  font-weight: $bold;

  &--modal {
    @include media(min, $sm) {
      label {
        flex-basis: 170px;
      }

      .control__wrapper {
        flex-basis: 346px;
      }

      .field-info--m {
        margin-left: 180px;
      }
    }
  }

  .control--prefix {
    input {
      padding-left: 92px;
    }
  }
}

.number-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;

  .number-actions__btn {
    height: math.div($input-height, 2);
    width: math.div($input-height, 2);
    color: getColor(light-blue);
    outline: none;
    font-size: $root;

    &:hover {
      background: getColor(light-blue);
      color: getColor(white);
    }

    &:focus {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(light-blue);
    }
  }
}
</style>
