<template>
  <div
    :class="`alert-${props.alertType}`"
    :dusk="props.dusk"
  >
    <slot name="prepend">
      <i :class="[`text-${props.alertType}`]">
        <AlertTriangle :size="24" />
      </i>
    </slot>

    <span>
      <slot />
    </span>

    <slot name="append" />
  </div>
</template>

<script setup lang="ts">
import { AlertTriangle } from 'lucide-vue-next';

interface Props {
  alertType?: 'info' | 'success' | 'danger' | 'warning'
  dusk?: string
}

const props = withDefaults(defineProps<Props>(), {
  alertType: 'info',
  dusk: 'alert',
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

%alert {
  @include transition;

  display: flex;
  align-items: center;
  border: 1px solid;
  min-height: 70px;

  :deep(> span) {
    margin-right: auto;
    padding: rem(15);
    line-height: 1.45;
    background: white;
    flex-grow: 1;
  }

  :deep(> i) {
    min-height: 70px;
    padding: rem(15);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex-shrink: 0;
  }

  :deep(> img) {
    width: 25px;
    height: auto;
  }

  :deep(> button) {
    color: currentColor;
  }

  * > :first-child {
    margin-top: 0;
  }
}

// COLORS
// Blue
.alert-info {
  @extend %alert;

  color: getColor(default, 1);
  border-color: getColor(info, 1);

  > i {
    background-color: getColor(info, 1);
  }
}

// Green
.alert-success {
  @extend %alert;

  color: getColor(success, 1);
  border-color: getColor(success, 1);

  > i {
    background-color: getColor(success, .1);
  }
}

// Red
.alert-danger {
  @extend %alert;

  color: getColor(danger, 1);
  border-color: getColor(danger, 1);

  > i {
    background-color: getColor(danger, .1);
  }
}

// Orange
.alert-warning {
  @extend %alert;

  color: getColor(warning, 1);
  border-color: getColor(warning, 1);

  > i {
    background-color: getColor(warning, .1);
  }
}
</style>
