export const QUESTIONNAIRE_AGE_BONUS = {
  under40: 4,
  under50: 2,
  over50: 0,
} as const;

export const calculateQuestionnaireAgeBonus = (age: number | null): number => {
  if (age === null || age === undefined) {
    return 0;
  }

  if (age < 40) {
    return QUESTIONNAIRE_AGE_BONUS.under40;
  }

  if (age < 50) {
    return QUESTIONNAIRE_AGE_BONUS.under50;
  }

  return QUESTIONNAIRE_AGE_BONUS.over50;
};

export const calculateQuestionnairePoints = (basePoints: number, age: number | null): number => {
  const ageBonus = calculateQuestionnaireAgeBonus(age);

  return basePoints + ageBonus;
};
