<script setup lang="ts">
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from '@headlessui/vue';

import LitTooltip from './Base/LitTooltip.vue';

const { options, error } = defineProps<{
  options: Record<string, {
    value: string
    label?: string
    subHeader?: string
    tooltip?: string
  }>
  error?: string
  dusk?: string
}>();

const model = defineModel<string>({ default: '' });
</script>

<template>
  <RadioGroup
    v-model="model"
    class="radio-group"
  >
    <RadioGroupOption
      v-for="option in options"
      :key="option.value"
      v-slot="{ checked }"
      :value="option.value"
      as="template"
      :dusk="dusk"
    >
      <li
        :class="[
          'radio-group-item',
          { 'radio-group-item--checked': checked }
        ]"
      >
        <span
          class="radio-group-item__control"
        />

        <RadioGroupLabel
          v-if="option?.subHeader"
          class="radio-group-item__label"
        >
          {{ option.subHeader }}
        </RadioGroupLabel>

        <RadioGroupDescription
          v-if="option?.label"
          class="radio-group-item__description"
        >
          {{ option.label }}

          <LitTooltip
            v-if="option.tooltip"
            tooltip-position="top"
          >
            <span v-html="option.tooltip" />
          </LitTooltip>
        </RadioGroupDescription>
      </li>
    </RadioGroupOption>
  </RadioGroup>

  <div v-if="error" class="pt-10 text-danger">
    {{ error }}
  </div>
</template>

<style lang="scss">
@import '@/sass/tools/variables';
@import '@/sass/tools/mixins';
@import '@/sass/tools/functions';

.radio-group {
  display: flex;
  gap: 10px;
  list-style: none;
  flex-direction: column;

  @include media(min, $sm) {
    flex-direction: row;
  }

  &-item {
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    outline: none;

    &__control {
      $control-bg: #d8e8c5;

      margin-bottom: 12px;
      width: rem(23);
      height: rem(23);
      border-radius: 50%;
      border: 1px solid $control-bg;
      background: $control-bg;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        width: rem(9);
        height: rem(9);
        border-radius: 50%;
        transition: 180ms transform ease-in-out;
        transform: scale(0);
        background: getColor(green);
      }
    }

    &--checked &__control {
      background: getColor(white);
      border-color: getColor(green);

      &::before {
        transform: scale(1);
      }
    }

    &__label {
      font-weight: $bold;
      font-size: $root-small;
    }

    &__description {
      font-size: $root-mini;
      padding-top: 12px;
      display: flex;
      align-items: center;
    }

    &--checked {
      border-color: getColor(green);
    }
  }
}
</style>
