export const clientsPersonalDataFields = [
  'personalData.firstName',
  'personalData.lastName',
  'personalData.personalIdNumber',
  'personalData.citizenship',
  'personalData.countryOfBirth',
  'personalData.birthplace',

  'personalData.permanentAddress.street',
  'personalData.permanentAddress.streetNumber',
  'personalData.permanentAddress.city',
  'personalData.permanentAddress.zip',
  'personalData.permanentAddress.country',

  'personalData.mailingAddress.street',
  'personalData.mailingAddress.streetNumber',
  'personalData.mailingAddress.city',
  'personalData.mailingAddress.zip',
  'personalData.mailingAddress.country',
];

export const consentsFields = [
  'consents.marketingPurposes',
  'consents.sharingInformation',
  'consents.usingEmailAddress',
  'consents.clientStatement',
];

export const contactInformationFields = [
  'contactInformation.firstName',
  'contactInformation.lastName',
  'contactInformation.email',
  'contactInformation.phoneNumber',
];

export const contributionAndStrategyFields = [
  'contributionAndStrategy.contributionAmount',
  'contributionAndStrategy.questionnaire.question1.answer',
  'contributionAndStrategy.questionnaire.question2.answer',
  'contributionAndStrategy.questionnaire.question3.answer',
  'contributionAndStrategy.questionnaire.question4.answer',
  'contributionAndStrategy.questionnaire.question5.answer',
  'contributionAndStrategy.questionnaire.question6.answer',
  'contributionAndStrategy.questionnaire.question7.answer',
  'contributionAndStrategy.questionnaire.question8.answer',
  'contributionAndStrategy.questionnaire.question9.answer',
  'contributionAndStrategy.questionnaire.question10.answer',
  'contributionAndStrategy.fundCombination',
  'contributionAndStrategy.selectedStrategy',
  'contributionAndStrategy.selectCustomStrategy',
  'contributionAndStrategy.investmentRiskCategory',
  'contributionAndStrategy.excludeConservativeFund',
  'contributionAndStrategy.recommendedStrategies',
];

export const currentContractFields = [
  'contractSettings.transferExistingRetirementSavings',
  'contractSettings.currentContract.type',
  'contractSettings.currentContract.companyName',
  'contractSettings.currentContract.contractNumber',
  'contractSettings.currentContract.contractEndsAt',
];

export const legalRequirementsFields = [
  'legalRequirements.paysTaxesInCzechia',
  'legalRequirements.isPoliticallyExposed',
  'legalRequirements.sourceOfIncome',
  'legalRequirements.occupation',
];
