<template>
  <div class="step">
    <div class="step__header">
      <div class="step__icon">
        <slot name="icon" />
      </div>

      <div class="step__label">
        <slot name="label" />
      </div>
    </div>

    <div class="step__container">
      <div class="step__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';
@import '@sass/tools/functions';

.step {
  &__header {
    font-family: $fontSecondary;
    font-size: 21px;
    display: flex;
    align-items: center;
    padding: 18px 0;

    @include media(min, $sm) {
      font-size: 22px;
    }
  }

  &__icon {
    border-radius: 50%;
    height: 23px;
    width: 23px;
    flex-shrink: 0;
    position: relative;
    font-size: $root-mini;
    font-weight: $bold;
    background: getColor(default);
    color: getColor(white);
    margin-right: 10px;

    @include media(min, $sm) {
      height: 41px;
      width: 41px;
      font-size: 21px;
    }

    :deep(.step__icon--number) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__container {
    margin-left: 11.5px;
    border: 0;
    position: relative;

    @include media(min, $sm) {
      margin-left: 20.5px;
    }

    [class*='alert-'] {
      min-height: 55px;
    }

    .underlined {
      text-decoration: underline;

      .rotated {
        transform: rotate(180deg);
      }
    }
  }

  &:not(:last-of-type) {
    .step__container {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        border-left: 1px solid getColor(default);
        top: -6px;
        bottom: -6px;

        @include media(min, $sm) {
          left: -1px;
          border-left-width: 2px;
        }
      }
    }
  }

  &__content {
    padding: 5px 0  5px 30.5px;
  }

  &.disabled {
    .step__icon {
      background: getColor(light-blue);
      color: getColor(default);
    }
  }
}
</style>
