<template>
  <div class="full-height">
    <TheHeader>
      <template #heading>
        <h1><strong>Distribuce</strong></h1>
      </template>

      <template #header_contact_phone>
        <a
          href="tel:+420224116655"
        >
          <img
            :src="getIconUrl('phone')"
            alt="Icon tel."
          ><span>224 116 655</span>
        </a>
      </template>
    </TheHeader>

    <main role="main">
      <div class="benefits bg-blue pt-30 pb-10">
        <div class="container--sm" />
      </div>
      <div class="container--md pb-30">
        <h2 class="center">
          Vyberte z možností
        </h2>

        <LitAlert
          v-if="!isBankIdOn"
          alert-type="warning"
          class="my-20"
        >
          {{ bankIdDeactivateText }}
        </LitAlert>

        <button
          v-if="!isProduction"
        >
          <p
            v-if="isBankIdEnabled"
            @click="isBankIdEnabled = false"
          >
            [ DEBUG ] Zakázat Bank iD
          </p>
          <p
            v-else
            @click="isBankIdEnabled = true"
          >
            [ DEBUG ] Povolit Bank iD
          </p>
        </button>

        <GuidePost
          :tiles="[
            {
              mainTitle: 'Sjednání nové smlouvy',
              redirectingText: 'Dochází k přesměrování na Sjednání nové smlouvy',
              color: 'green',
              dusk: 'distribution',
              iconName: 'savings-outline',
              handler: () => redirectToNewContract(false),
              loading
            },
            {
              mainTitle: 'Převod smlouvy v rámci ČSOB PS',
              redirectingText: 'Dochází k přesměrování na Převod smlouvy',
              color: 'blue',
              dusk: 'transfer',
              iconName: 'cached',
              handler: redirectToTransfer,
              description: 'Převod z Penzijního připojištění do Doplňkového '
                + 'penzijního spoření v rámci ČSOB PS',
              loading
            },
            {
              mainTitle: 'Převod smlouvy z jiné PS',
              redirectingText: 'Dochází k přesměrování na Převod smlouvy',
              color: '#092f68',
              dusk: 'jps',
              iconName: 'redo',
              subLinks: [
                {
                  text: 'S podpisem Bank iD',
                  subtext: 'elektronické odeslání',
                  addInfo: '(účastník, občan ČR starší 18 let)',
                  isDisabled: !isBankIdOn,
                  handler: redirectToJps
                },
                {
                  text: 'Bez podpisu Bank iD',
                  subtext: 'papírové odeslání',
                  addInfo: '(úředně ověřený podpis, zaslání do původní PS)',
                  handler: () => redirectToNewContract(true)
                }
              ],
              description: `Proces založení nové smlouvy s&nbsp;elektronickým podpisem a&nbsp;výpovědi stávající smlouvy u&nbsp;jiné PS.<br><br>`
                + `Vyberte variantu podpisu výpovědi:`,
              loading
            },
            {
              mainTitle: 'Výplata ze smlouvy',
              redirectingText: 'Dochází k přesměrování na Ukončení staré smlouvy',
              color: 'red',
              dusk: 'termination',
              iconName: 'close-small',
              subLinks: [
                {
                  text: 'S podpisem Bank iD',
                  subtext: 'elektronické odeslání',
                  addInfo: '(účastník, občan ČR starší 18 let)',
                  isDisabled: !isBankIdOn,
                  handler: continueWithBankId
                },
                {
                  text: 'Bez podpisu Bank iD',
                  subtext: 'papírové odeslání',
                  addInfo: '(zákonný zástupce, opatrovník, cizinec)',
                  handler: continueWithoutBankId
                }
              ],
              description: `Jednorázové výplaty<ul><li>Starobní penze z TRF i DPS</li><li>Výsluhové penze z TRF</li><li>Odbytné z TRF i DPS</li></ul><br>`
                + `Postupné výplaty<ul><li>Starobní penze z DPS</li></ul>`,
              loading
            },
            {
              mainTitle: 'Jiné typy výplat',
              redirectingText: 'Jiné typy výplat',
              color: 'brown',
              dusk: 'anotherPayment',
              href: 'https://www.csob-penze.cz/distribuce/jak-sepsat-smlouvuzmenu/',
              target: '_blank',
              iconName: 'more-gray',
              buttonText: 'Stáhnout formuláře',
              description: '<strong>Využijte v případě:</strong><br>'
                + `Doživotní, invalidní penze, pozůstalostních
                dávek nebo pro výplaty dávek do zahraničí.`
            },
            {
              mainTitle: 'Úprava příspěvku a PDO',
              description: `Úprava příspěvku<br><ul style='padding-bottom: 5px; padding-top: 5px'><li>Účastníka</li><li>Zaměstnavatele</li></ul>Program daňové optimalizace`,
              footer: `Pro jinou úpravu smlouvy využijte formulář:<br><a style='color:#092f68;' href='https://www.csob-penze.cz/media/2025/01/520-Dodatek-smlouvy-DPS_TRF-distribuce-1224e.pdf' target='_blank'>Dodatek ke smlouvě o PS</a>`,
              color: 'yellow',
              iconName: 'edit_yellow',
              handler: () => redirectToChangeRequest('contractSettings'),
              isVisible: displayRequestChangesTile
            }
          ]"
        />

        <p
          v-if="displayContractNumberGeneration"
          class="text-center contract-number"
        >
          Možnost
          <span @click="redirectToContractNumberGenerator">vygenerování čísla smlouvy</span> předem.
        </p>
      </div>

      <LitModal
        v-model="displayBankIdFailedModal"
      >
        <template #header>
          <h3>
            <strong>Něco se pokazilo</strong>
          </h3>
        </template>

        <template #body>
          <LitAlert class="alert center my-30" alert-type="warning">
            <h3>Nemůžeme vaši žádost dokončit pomocí Bank&nbsp;iD</h3>
          </LitAlert>

          <div
            v-if="errorMessages.length > 0"
            class="my-20"
          >
            <p v-if="!isErrorFromBankId"> a to z důvodu, že Váš profil evidovaný v Bank iD:</p>

            <ul
              v-for="(message, index) in errorMessages"
              :key="index"
              class="list personal-data__modal"
            >
              <li>{{ message }}</li>
            </ul>
          </div>

          <p class="mt-20">
            Překontrolujte vyplněné osobní údaje
            nebo zvolte způsob podání bez podpisu Bank&nbsp;iD.
          </p>
        </template>

        <template #footer>
          <div class="flex flex-center">
            <LitButton
              dusk="close-modal"
              @click="closeBankIdRejectModal"
            >
              <span>
                ROZUMÍM
              </span>
            </LitButton>
          </div>
        </template>
      </LitModal>

      <LitModal
        v-model="isTerminationPopUpVisible"
        :max-width="480"
        dusk="termination"
      >
        <template #header>
          <IconWrapper type="danger" class="text-danger mb-20 m-center">
            <TriangleAlert :size="24" />
          </IconWrapper>

          <h3>Opravdu chcete ukončit smlouvu?</h3>
        </template>

        <template #body>
          <LitAlert alert-type="danger">
            Předčasným ukončením
            <b>můžete přijít o&nbsp;část svých úspor.</b>
          </LitAlert>
        </template>

        <template #footer>
          <div class="flex flex-center content-center flex-dir-column">
            <LitButton
              class="mb-20"
              @click="redirectToCalculator"
            >
              O KOLIK PŘIJDU?
            </LitButton>

            <LitButton
              variant="link"
              @click="isTerminationPopUpVisible = false"
            >
              Nechci ukončit
            </LitButton>
          </div>
        </template>
      </LitModal>
    </main>

    <TheFooter />
  </div>
</template>

<script lang="ts">
import { GuidePost } from '@csob-penze/csob-penze-design';
import { TriangleAlert } from 'lucide-vue-next';

import { contracts, signatures } from '../api';
import LitAlert from '../components/Base/LitAlert.vue';
import LitButton from '../components/Base/LitButton.vue';
import LitModal from '../components/Base/LitModal.vue';
import IconWrapper from '../components/IconWrapper.vue';
import { bankIdErrorMessage } from '../data/bankIdErrorMessages.ts';
import { getHomepageUrl, getIconUrl } from '../utils';
import TheFooter from './TheFooter.vue';
import TheHeader from './TheHeader.vue';

import env from '@/js/env.ts';

import '@csob-penze/csob-penze-design/dist/csob-penze-design.css';

interface State {
  loading: boolean
  transferRedirecting: boolean
  jpsRedirecting: boolean
  terminationRedirecting: boolean
  newContractRedirecting: boolean
  isTerminationPopUpVisible: boolean
  isBankId: boolean
  displayBankIdFailedModal: boolean
  homeRedirectPath: string
  errorMessages: string[]
  isErrorFromBankId: boolean
  isBankIdEnabled: boolean
  changeRequestRedirecting: boolean
}

export default {
  components: {
    LitAlert,
    LitModal,
    TheHeader,
    TheFooter,
    GuidePost,
    LitButton,
    IconWrapper,
    TriangleAlert,
  },

  async beforeRouteEnter (from, _to, next) {
    const allowedQueryParams = [
      'newContract',
      'transfer',
      'jps',
      'termination',
      'changeRequest',
    ];

    // @ts-expect-error: TODO: fix this
    if (!allowedQueryParams.includes(from.query.form)) {
      next();
      return;
    }

    try {
      const contractType = from.query.form === 'newContract' ? 'distribution' : from.query.form;

      if (['distribution', 'transfer', 'jps', 'changeRequest'].includes(contractType as string)) {
        const response = await contracts.create(contractType as string);

        let signature;

        if (contractType === 'jps') {
          signature = [
            {
              signer: 'distributor',
              signMethod: 'digital',
            },
            {
              signer: 'client',
              signMethod: 'digital',
            },
            {
              signer: 'client',
              signMethod: 'bankId',
            },
          ];
        } else {
          signature = [
            {
              signer: 'distributor',
              signMethod: 'offline',
            },
            {
              signer: 'client',
              signMethod: 'offline',
            },
          ];
        }

        await signatures.create(response.contract.uuid, JSON.stringify(signature));

        next({
          name: `${contractType}.beforeStart`,
          params: { contractUuid: response.contract.uuid },
        });
      } else {
        next({
          name: 'termination.calculator',
          query: { bankId: from.query.bankId },
        });
      }
    } catch (e) {
      console.warn(e);

      next({
        name: 'error',
      });
    }
  },

  setup () {
    return {
      getIconUrl,
    };
  },

  data: (): State => ({
    loading: false,
    transferRedirecting: false,
    jpsRedirecting: false,
    terminationRedirecting: false,
    newContractRedirecting: false,
    isTerminationPopUpVisible: false,
    isBankId: false,
    displayBankIdFailedModal: false,
    homeRedirectPath: getHomepageUrl(),
    errorMessages: [],
    isErrorFromBankId: false,
    isBankIdEnabled: true,
    changeRequestRedirecting: false,
  }),

  computed: {
    isRedirecting () {
      return this.transferRedirecting || this.terminationRedirecting || this.newContractRedirecting || this.changeRequestRedirecting;
    },

    isProduction () {
      return env.APP_ENV === 'production';
    },

    isBankIdOn () {
      if (this.isProduction) {
        return env.BANKID_ENABLED === true;
      }

      return this.isBankIdEnabled;
    },

    bankIdDeactivateText () {
      return `Momentálně evidujeme výpadek na straně poskytovatele služby Bank iD. Omlouváme se za případné komplikace. ${env.BANKID_DISABLED_END_DATE_INFO_TEXT}`;
    },

    offlineSignatures () {
      return [
        {
          signer: 'distributor',
          signMethod: 'offline',
        },
        {
          signer: 'client',
          signMethod: 'offline',
        },
      ];
    },

    displayRequestChangesTile () {
      return env.SHOW_REQUEST_CHANGES;
    },

    displayContractNumberGeneration () {
      return env.ALLOW_CONTRACT_NUMBER_GENERATION;
    },
  },

  watch: {
    displayBankIdFailedModal (newVal) {
      if (newVal === false && this.$route.query?.bankIdSign === 'failed') {
        window.location.href = this.homeRedirectPath;
      }
    },
  },

  mounted () {
    if (this.$route.query?.bankIdSign === 'failed' || this.$route.query?.bankIdSign === 'rejected') {
      const errors = JSON.parse(this.$route.query.errors as string) as string[];
      this.errorMessages = this.getCustomBankIdErrorMessages(errors);

      this.displayBankIdFailedModal = true;
    }
  },

  methods: {
    getCustomBankIdErrorMessages (errors: string[]) {
      const messages: string[] = [];

      errors.forEach((error: string) => {
        bankIdErrorMessage.forEach((message) => {
          if (error === message.key) {
            messages.push(message.label);

            this.isErrorFromBankId = message.isBankIdError;
          }
        });
      });

      return messages as string[];
    },

    closeBankIdRejectModal () {
      this.displayBankIdFailedModal = false;
      this.$router.replace({ query: undefined });
      window.location.href = this.homeRedirectPath;
    },

    showTerminationPopup () {
      if (this.isRedirecting) {
        return;
      }

      this.isTerminationPopUpVisible = true;
    },

    continueWithBankId () {
      if (this.isBankIdOn) {
        this.isBankId = true;
        this.showTerminationPopup();
      }
    },

    continueWithoutBankId () {
      this.isBankId = false;
      this.showTerminationPopup();
    },

    async redirectToNewContract (withTransfer: boolean) {
      if (!this.isRedirecting) {
        this.newContractRedirecting = true;

        try {
          const response = await contracts.create('distribution');

          const signature = this.offlineSignatures;

          await signatures.create(response.contract.uuid, JSON.stringify(signature));

          this.loading = true;
          this.newContractRedirecting = true;

          await this.$router.push({
            name: 'distribution.beforeStart',
            params: { contractUuid: response.contract.uuid },
            query: {
              ...this.$route.query,
              isJps: withTransfer ? 'true' : 'false',
            },
          });
        } catch (e: any) {
          const status = e?.response?.status;
          const conflictingUuid = e?.response?.headers['x-conflicts-with'];

          if (status === 409 && conflictingUuid) {
            console.warn('Redirecting to ongoing contract', conflictingUuid);

            await this.$router.push({
              name: 'distribution.beforeStart',
              params: { contractUuid: conflictingUuid },
              query: this.$route.query,
            });
          } else {
            await this.$router.push({
              name: 'error',
            });
          }
        } finally {
          this.loading = false;
          this.newContractRedirecting = false;
        }
      }
    },

    async redirectToChangeRequest (formChange: string) {
      if (!this.isRedirecting) {
        try {
          this.changeRequestRedirecting = true;
          const response = await contracts.create('changeRequest');

          const signature = this.offlineSignatures;
          await signatures.create(response.contract.uuid, JSON.stringify(signature));

          this.loading = true;

          await this.$router.push({
            name: `changeRequest.beforeStart`,
            params: { contractUuid: response.contract.uuid },
            query: {
              ...this.$route.query,
              form: formChange,
            },
          });
        } catch (e: any) {
          const status = e?.response?.status;
          const conflictingUuid = e?.response?.headers['x-conflicts-with'];

          if (status === 409 && conflictingUuid) {
            await this.$router.push({
              name: `changeRequest.beforeStart`,
              params: { contractUuid: conflictingUuid },
              query: this.$route.query,
            });
          } else {
            await this.$router.push({
              name: 'error',
            });
          }
        } finally {
          this.loading = false;
          this.changeRequestRedirecting = false;
        }
      }
    },

    async redirectToJps () {
      if (!this.isRedirecting && this.isBankIdOn) {
        try {
          this.jpsRedirecting = true;
          const response = await contracts.create('jps');

          const signature = [
            {
              signer: 'distributor',
              signMethod: 'digital',
            },
            {
              signer: 'client',
              signMethod: 'digital',
            },
            {
              signer: 'client',
              signMethod: 'bankId',
            },
          ];

          await signatures.create(response.contract.uuid, JSON.stringify(signature));

          this.loading = true;
          this.jpsRedirecting = true;

          await this.$router.push({
            name: 'jps.beforeStart',
            params: { contractUuid: response.contract.uuid },
            query: this.$route.query,
          });
        } catch (e: any) {
          const status = e?.response?.status;
          const conflictingUuid = e?.response?.headers['x-conflicts-with'];

          if (status === 409 && conflictingUuid) {
            await this.$router.push({
              name: 'jps.beforeStart',
              params: { contractUuid: conflictingUuid },
              query: this.$route.query,
            });
          } else {
            await this.$router.push({
              name: 'error',
            });
          }
        } finally {
          this.loading = false;
          this.jpsRedirecting = false;
        }
      }
    },

    async redirectToTransfer () {
      if (!this.isRedirecting) {
        try {
          this.transferRedirecting = true;
          const response = await contracts.create('transfer');

          const signature = this.offlineSignatures;

          await signatures.create(response.contract.uuid, JSON.stringify(signature));

          this.loading = true;
          this.transferRedirecting = true;

          await this.$router.push({
            name: 'transfer.beforeStart',
            params: { contractUuid: response.contract.uuid },
            query: this.$route.query,
          });
        } catch (e: any) {
          const status = e?.response?.status;
          const conflictingUuid = e?.response?.headers['x-conflicts-with'];

          if (status === 409 && conflictingUuid) {
            console.warn('Redirecting to ongoing contract', conflictingUuid);

            await this.$router.push({
              name: 'transfer.beforeStart',
              params: { contractUuid: conflictingUuid },
              query: this.$route.query,
            });
          } else {
            await this.$router.push({
              name: 'error',
            });
          }
        } finally {
          this.loading = false;
          this.transferRedirecting = false;
        }
      }
    },

    async redirectToContractNumberGenerator () {
      try {
        await this.$router.push({
          name: 'contractNumberGenerator.distributorInformation',
        });
      } catch (e) {
        console.warn(e);
      }
    },

    async redirectToCalculator () {
      try {
        await this.$router.push({
          name: 'termination.calculator',
          query: { bankId: this.isBankId ? 'true' : 'false' },
        });
      } catch (e) {
        console.warn(e);

        await this.$router.push({
          name: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

.full-height {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contract-number {
  padding: 10px;
  margin-top: 10px;

  span {
    cursor: pointer;
    border-bottom: 1px solid var(--color-default);

    &:hover {
      border-bottom: none;
    }
  }
}

.alert-warning:first-child {
  margin-top: 20px;
}

.alert h3 {
  margin-bottom: 0;
}

.box__description a {
  color: #092f68 !important;
}

.personal-data {
  &__modal {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: .3rem;

      span {
        text-align: left;
        width: 30%;
      }
    }
  }
}
</style>
