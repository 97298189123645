<template>
  <div>
    <DistributionStepper current-step="contractDocumentsAndConsents" />

    <div class="container--sm mt-20">
      <DocumentsAndConsents
        namespace="distribution"
        :validate-fnc="validateField"
        :display-tied-agent-info="store.beforeStart.isTiedAgent.value"
      />
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="distribution"
      previous-step="contactInformation"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import DistributionStepper from '@/js/components/Steppers/DistributionStepper.vue';
import { consentsFields } from '@/js/data/validationFields/commonFields.ts';

import { useDistributionStore } from '@/js/stores';
import { scrollToError } from '@/js/utils';

import DocumentsAndConsents from '@/js/views/common/DocumentsAndConsents.vue';
import StepButtons from '@/js/views/common/StepButtons.vue';

const store = useDistributionStore();
const { validateFields, validateField } = store;

const {
  transferUuid,
  terminationUuid,
  isFromRequest,
  consentsPageVisitNumber,
  contractSettings,
} = storeToRefs(store);

const route = useRoute();
const router = useRouter();

const validating = ref(false);
const error = ref(false);

const nextStep = computed(() => {
  if (isFromRequest.value === true && consentsPageVisitNumber.value.value === 0) {
    return 'distribution.legalRequirements';
  }

  return 'distribution.personalData';
});

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: consentsFields,
    });

    await router.push({
      name: nextStep.value,
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

onMounted(async () => {
  if (route.query.transferUuid || route.query.terminationUuid) {
    transferUuid.value.value = route.query.transferUuid as string;
    terminationUuid.value.value = route.query.terminationUuid as string;

    contractSettings.value.transferExistingRetirementSavings.value = true;

    const { contractUuid } = route.params;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: consentsFields,
      documentsSent: true,
    });
  }
});

onBeforeRouteLeave(() => {
  if (transferUuid.value.value !== null) {
    consentsPageVisitNumber.value.value = consentsPageVisitNumber.value.value + 1;
  }

  if (terminationUuid.value.value !== null) {
    consentsPageVisitNumber.value.value = consentsPageVisitNumber.value.value + 1;
  }
});
</script>
