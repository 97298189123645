export type Category = 'A' | 'B' | 'C';
type Matrix = Record<Category, Record<Category, Category>>;

export const QUESTION_89_MATRIX = {
  A: { A: 'A', B: 'B', C: 'B' },
  B: { A: 'B', B: 'B', C: 'C' },
  C: { A: 'B', B: 'C', C: 'C' },
} as const;

export const QUESTION_10_MATRIX = {
  A: { A: 'A', B: 'A', C: 'B' },
  B: { A: 'A', B: 'B', C: 'B' },
  C: { A: 'A', B: 'B', C: 'C' },
} as const;

export const getMatrixResult = (matrix: Matrix, row: Category, column: Category): Category => {
  return matrix[row][column];
};
