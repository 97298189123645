<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useConfirmDialog } from '@vueuse/core';
import { TriangleAlert } from 'lucide-vue-next';

import LitButton from '@/js/components/Base/LitButton.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import IconWrapper from '@/js/components/IconWrapper.vue';
import LitModal from '@/js/components/Base/LitModal.vue';

import { removePersistedData } from '@/js/utils';

const props = defineProps<Props>();

const { isRevealed, reveal, cancel, confirm, onConfirm } = useConfirmDialog();

interface Props {
  routeName: string
  storageKey: 'distribution'
}

const router = useRouter();

onConfirm(() => {
  removePersistedData(props.storageKey);
  router.push({ name: props.routeName });
});
</script>

<template>
  <slot name="button" v-bind="{ open: reveal }">
    <button
      type="button"
      @click="reveal"
    >
      Vybrat jinou distribuci
    </button>
  </slot>

  <LitModal
    :model-value="isRevealed"
    :max-width="480"
    @close="cancel"
  >
    <template #icon>
      <LitIconSvg icon-name="icon-state_alert" />
      <IconWrapper type="danger">
        <TriangleAlert :size="24" class="text-danger" />
      </IconWrapper>
    </template>

    <template #header>
      <h3>
        Změnou distribuce zrušíte<br> rozpracovaný proces
      </h3>
    </template>

    <template #body>
      <p class="text-center">
        Opravdu chcete zrušit rozpracovaný proces?<br>
        Změny nebudou uloženy a celá akce je nevratná.
      </p>
    </template>

    <template #footer>
      <div class="flex flex-center content-center flex-dir-column">
        <LitButton
          size="sm"
          class="mb-24"
          @click="cancel"
        >
          ZŮSTAT ZDE
        </LitButton>

        <LitButton
          size="sm"
          variant="link"
          @click="confirm"
        >
          Ano, chci změnit distribuci
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>
