<!-- eslint-disable ts/ban-ts-comment -->
<!-- eslint-disable @typescript-eslint/ban-ts-comment -->
<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* TODO: Refactor to use useQuestionnaire composable */

import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue';

import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import CustomStrategySelection from '@/js/components/CustomStrategySelection.vue';
import StrategySelection from '@/js/components/StrategySelection.vue';
import availableQuestions from '@/js/data/financialQuestionnaire.json' with { type: 'json' };
import { getIconUrl, scrollToError } from '@/js/utils.ts';

import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';

import type { Question } from '@/js/composables/useQuestionnaire.ts';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';
import StepButtons from '@/js/views/common/StepButtons.vue';
import gtm from '@/js/services/gtm';

const route = useRoute();
const router = useRouter();

const CustomStrategyWarningModal = defineAsyncComponent(() =>
  import('@/js/components/Modals/CustomStrategyWarningModal.vue'),
);

const store = useChangeRequestStore();
const { validateField, validateFields } = store;
const { strategy } = storeToRefs(store);

const validating = ref(false);
const showFormsOfferModal = ref(false);
const showCustomStrategyModal = ref(false);

const preferConservativeFund = ref(false);

function useQuestionnaire (q: Question[], answers: Record<string, any>) {
  const internalQuestions = ref(q);
  const loading = ref(false);

  const questions = computed(() => {
    return internalQuestions.value.map((question) => ({
      ...question,
      answer: answers[`question${question.id}`].value,
    }));
  });

  const lastAnsweredQuestion = computed(() => {
    let lastQuestion = 0;

    questions.value.forEach((question) => {
      if (question.answer !== null) {
        lastQuestion = question.id;
      }
    });

    return lastQuestion;
  });

  const status = computed<{
    totalPoints: number | null
    unansweredQuestions: number
    isKo: boolean
  }>(() => {
    let questionsToAnswer = questions.value.length;
    let points = 0;

    for (const question of questions.value) {
      const answerId = question.answer as Partial<keyof typeof question.optionPoints>;

      if (question.answer !== null) {
        questionsToAnswer -= 1;

        if (answerId === question.koAnswer) {
          return {
            totalPoints: 0,
            unansweredQuestions: 3,
            isKo: true,
          };
        }

        points += question.optionPoints[answerId] || 0;
      }
    }

    return {
      totalPoints: points,
      unansweredQuestions: questionsToAnswer,
      isKo: false,
    };
  });

  // When the user receives a KO, don't display the following question (+0).
  //  Otherwise, show (+1).
  const isQuestionVisible = (questionId: number) => {
    return questionId <= lastAnsweredQuestion.value + (status.value.isKo ? 0 : 1);
  };

  const resetAnswer = (questionId: number, cb: (questionId: number, answer: string | null) => void) => {
    if (typeof questionId !== 'number' || questionId < 1) {
      throw new Error('Invalid questionId');
    }

    status.value.totalPoints = 0;

    questions.value.forEach((question) => {
      if (question.id >= questionId) {
        cb(question.id, null);
      }
    });
  };

  return {
    questions,
    lastAnsweredQuestion,
    isQuestionVisible,
    status,
    loading,
    resetAnswer,
  };
}

const {
  questions,
  isQuestionVisible,
  status,
  loading,
  resetAnswer,
} = useQuestionnaire(availableQuestions.questions as Question[], strategy.value.questionnaire);

const setAnswer = (questionId: number, answer: string | null) => {
  // @ts-expect-error: TS doesn't know that the value is a ref
  questionnaire.value[`question${questionId}`].value = answer;
};

watch(status, (newValue) => {
  if (newValue.unansweredQuestions === 0 || newValue.isKo) {
    strategy.value.totalPoints.value = newValue.totalPoints;
    loading.value = true;
    strategy.value.showQuestionnaire.value = false;

    setTimeout(() => {
      loading.value = false;
    }, 1700);
  }

  if (newValue.totalPoints === 0 && newValue.unansweredQuestions !== 0 && !newValue.isKo) {
    strategy.value.totalPoints.value = null;
  }
});

const isQuestionnaireProcessed = computed(() => {
  return strategy.value.totalPoints.value !== null && !loading.value;
});

const showStrategySelection = computed(() => {
  const investmentStrategy = contributionAndStrategy.value.investmentRiskCategory.value;
  // do not show for investment strategy E or D
  if (investmentStrategy === 'E' || investmentStrategy === 'D') {
    return false;
  }

  return isQuestionnaireProcessed.value || selectCustomStrategy.value;
});

watch(showStrategySelection, (val) => {
  if (val === false) {
    strategy.value.selectedStrategy.value = null;
  }
});

function offerForms () {
  showFormsOfferModal.value = true;
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [

      ],
    });

    await router.push({
      name: 'changeRequest.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zmena-smlouvy');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation clientsStatementError: ${e}`);
  } finally {
    validating.value = false;
  }
}

function onCustomStrategyClick () {
  resetAnswer(1, setAnswer);

  strategy.value.selectedStrategy.value = null;
  strategy.value.selectCustomStrategy.value = true;
  strategy.value.showQuestionnaire.value = false;
  showCustomStrategyModal.value = false;
}

function onFillQuestionnaireClick () {
  strategy.value.selectedStrategy.value = null;
  strategy.value.selectCustomStrategy.value = false;
  strategy.value.showQuestionnaire.value = true;
}

function setShowQuestionnaire () {
  if (strategy.value.selectCustomStrategy.value === false) {
    strategy.value.showQuestionnaire.value = !isQuestionnaireProcessed.value;
  } else {
    strategy.value.showQuestionnaire.value = false;
  }
}

onMounted(() => {
  setShowQuestionnaire();
});
</script>

<template>
  <div class="container--sm">
    <h3 dusk="questionnaire-heading">
      Nastavte správnou strategii penzijního spoření
    </h3>

    <div class="stepper-vertical">
      <div
        class="step"
        dusk="invest-questionnaire"
      >
        <div
          id="questionnaire"
          class="step__header"
        >
          <div class="step__icon">
            <span class="step__icon--number">
              1
            </span>
          </div>

          <div class="step__label">
            Vyplňte s klientem krátký penzijní dotazník
          </div>
        </div>

        <div class="step__container">
          <div class="step__content">
            <LitAlert
              v-if="isQuestionnaireProcessed"
              dusk="questionnaire-successful-alert"
              alert-type="success"
              class="mb-20"
            >
              Penzijní dotazník byl úspěšně vyplněn a vyhodnocen.
            </LitAlert>

            <LitAlert
              v-if="!showStrategySelection"
              dusk="choose-own-strategy-alert"
              class="mb-20"
            >
              Pokud si klient nepřeje vyplňovat krátký penzijní dotazník,

              <button
                class="btn-simple"
                type="button"
                @click="showCustomStrategyModal = true"
              >
                může si vybrat sám vlastní strategii
              </button>.
            </LitAlert>

            <LitAlert
              v-if="strategy.selectCustomStrategy.value"
              dusk="recommended-strategy-choice-alert"
              alert-type="warning"
              class="mb-20"
            >
              Pokud si chce klient nechat doporučit vhodnou strategii,

              <button
                class="btn-simple"
                type="button"
                @click="onFillQuestionnaireClick"
              >
                vyplňte s ním krátký penzijní dotazník
              </button>.
            </LitAlert>

            <div
              v-if="isQuestionnaireProcessed"
              dusk="toggle-show-questionnaire"
              class="btn-simple mb-20"
              @click="strategy.showQuestionnaire.value = !strategy.showQuestionnaire.value"
            >
              <span v-if="!strategy.showQuestionnaire.value">
                Zobrazit odpovědi penzijního dotazníku, případně dotazník upravit
              </span>

              <span v-else>
                Skrýt odpovědi penzijního dotazníku
              </span>

              <img
                :src="getIconUrl('arrow_down-green')"
                alt="šipka"
                class="ml-7"
                :class="{ rotated: strategy.showQuestionnaire.value }"
              >
            </div>

            <div v-show="!loading && strategy.showQuestionnaire.value">
              <div
                v-for="question in questions"
                :key="question.id"
                :dusk="`question${question.id}`"
                :class="{ selected: question.answer !== null }"
                class="question mb-20"
              >
                <template
                  v-if="isQuestionVisible(question.id)"
                >
                  <div class="question__header">
                    <div class="question__count">
                      {{ question.id }}.
                    </div>

                    <div class="question__label">
                      {{ question.label }}
                    </div>

                    <div class="question__action">
                      <button
                        v-if="question.answer !== null"
                        type="button"
                        dusk="change-answer"
                        class="btn-simple"
                        @click="resetAnswer(question.id, setAnswer)"
                      >
                        <i>
                          <img
                            :src="getIconUrl('icon-edit')"
                            alt="Ikona upravit"
                          >
                        </i>
                        Změnit odpověď
                      </button>
                    </div>
                  </div>

                  <div class="question__content">
                    <ServerValidatedFormField
                      v-slot="{ value, input, errors }"
                      namespace="strategyChange"
                      :debounce="0"
                      :default-value="question.answer"
                      :field-path="`questionnaire.question${question.id}`"
                      :validate-on-input="true"
                      @validate="validateField"
                    >
                      <LitRadio
                        :show-only-selected="question.answer !== null"
                        :name="`question${question.id}Options`"
                        :options="question.options"
                        :model-value="value"
                        :error="errors.length > 0 ? errors[0] : ''"
                        @update:model-value="input"
                      />
                    </ServerValidatedFormField>
                  </div>
                </template>
              </div>
            </div>

            <div v-if="loading">
              <div class="flex flex-center mb-20 flip">
                <img
                  :src="getIconUrl('icon-loader_big')"
                  alt="Načítání..."
                  class="spin"
                >
              </div>
              <div class="text-warning text-center text-bold">
                Vyhodnocujeme klientovy odpovědi, chvíli strpení
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        dusk="strategy-step"
        class="step"
        :class="{ disabled: !showStrategySelection }"
      >
        <div
          id="customStrategy"
          class="step__header"
        >
          <div class="step__icon">
            <span class="step__icon--number">
              2
            </span>
          </div>

          <div class="step__label">
            Vyberte s klientem strategii
          </div>
        </div>

        <div class="step__container">
          <div class="step__content">
            <LitAlert
              v-if="!showStrategySelection"
              dusk="step-over-questionnaire-alert"
              alert-type="warning"
            >
              Vyplňte s klientem výše uvedený penzijní dotazník.
              Na jeho základě doporučíme spořicí strategii vhodnou právě pro vašeho klienta.
              Pokud si klient přeje vybrat strategii sám,

              <button
                class="btn-simple"
                type="button"
                @click="showCustomStrategyModal = true"
              >
                přeskočte penzijní dotazník
              </button>.
            </LitAlert>

            <div v-if="strategy.selectCustomStrategy.value">
              <p class="mb-30">
                Výběrem vlastní strategie spoření se klient vystavuje riziku,
                že zvolená strategie spoření nebude odpovídat
                jeho cílům, odborným znalostem nebo zkušenostem potřebným
                pro pochopení rizik souvisejících s investováním.
              </p>

              <ServerValidatedFormField
                v-slot="{ value, input, errors }"
                namespace="strategyChange"
                field-path="selectedStrategy"
                :debounce="0"
                :validate-on-input="true"
                @validate="validateField"
              >
                <CustomStrategySelection
                  v-model:fund-combination="strategy.fundCombination.value"
                  :error="errors.length > 0 ? errors[0] : ''"
                  :model-value="value"
                  :is-custom-strategy="selectCustomStrategy"
                  :prefer-conservative-fund="preferConservativeFund"
                  @update:model-value="input"
                />
              </ServerValidatedFormField>
            </div>

            <div v-if="isQuestionnaireProcessed">
              <p class="mb-30">
                Na základě odpovědí klienta doporučujeme níže uvedené strategie.
              </p>

              <ServerValidatedFormField
                v-slot="{ value, input, errors }"
                namespace="strategyChange"
                field-path="selectedStrategy"
                :debounce="0"
                :validate-on-input="true"
                @validate="validateField"
              >
                <StrategySelection
                  dusk="strategy-selection"
                  :total-points="strategy.totalPoints.value"
                  :model-value="value"
                  :error="errors.length > 0 ? errors[0] : ''"
                  @update:model-value="input"
                />
              </ServerValidatedFormField>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="separator my-30">

    <div class="flex flex-between">
      <StepButtons
        namespace="changeRequest"
        previous-step="clientData"
        @submit="offerForms"
      />
    </div>

    <ChangeRequestFormsOfferModal
      v-model="showFormsOfferModal"
      :forms="store.changeSettings.involvedForms.value"
      @close="showFormsOfferModal = false"
      @finish="handleSubmit"
    />
  </div>

  <CustomStrategyWarningModal
    :model-value="showCustomStrategyModal"
    @close="showCustomStrategyModal = false"
    @click="onCustomStrategyClick"
  />
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';
@import '@sass/tools/functions';

.stepper-vertical {
  margin-bottom: 20px;

  .step {
    &__header {
      font-family: $fontSecondary;
      font-size: 21px;
      display: flex;
      align-items: center;
      padding: 18px 0;

      @include media(min, $sm) {
        font-size: 22px;
      }
    }

    &__icon {
      border-radius: 50%;
      height: 23px;
      width: 23px;
      flex-shrink: 0;
      position: relative;
      font-size: $root-mini;
      font-weight: $bold;
      background: getColor(default);
      color: getColor(white);
      margin-right: 10px;

      @include media(min, $sm) {
        height: 41px;
        width: 41px;
        font-size: 21px;
      }

      &--number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        height: fit-content;
        margin-top: -2px;

        @include media(min, $sm) {
          height: 20.5px;
        }
      }
    }

    &__container {
      margin-left: 11.5px;
      border: 0;
      position: relative;

      @include media(min, $sm) {
        margin-left: 20.5px;
      }

      [class*='alert-'] {
        min-height: 55px;
      }

      .underlined {
        text-decoration: underline;

        .rotated {
          transform: rotate(180deg);
        }
      }
    }

    &:not(:last-of-type) {
      .step__container {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          border-left: 1px solid getColor(default);
          top: -6px;
          bottom: -6px;

          @include media(min, $sm) {
            left: -1px;
            border-left-width: 2px;
          }
        }
      }
    }

    &__content {
      padding: 5px 0  5px 30.5px;
    }

    &.disabled {
      .step__icon {
        background: getColor(light-blue);
        color: getColor(default);
      }
    }
  }
}

.question {
  &__header {
    display: flex;
    margin-bottom: 10px;
    font-weight: $bold;
    line-height: 1.45;
  }

  &__count {
    flex-shrink: 0;
    margin-right: 15px;
  }

  &__label {
    margin-right: 10px;
    flex-grow: 1;
  }

  &__action {
    flex-shrink: 0;
    justify-self: flex-end;
    font-weight: $normal;
  }

  &__content {
    margin-left: 10px;
  }

  &.selected &__header > *:not(.question__action) {
    opacity: .5;
  }

  &.selected &__content > * {
    opacity: .5;
  }
}

.btn-outline {
  margin-left: 5px;
}

.btn-green {
  margin-right: 5px;
}
</style>
