<script setup lang="ts">
import type { Question } from '@/js/types/questionnaire';

import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitTooltip from '@/js/components/Base/LitTooltip.vue';
import LitTextarea from '@/js/components/Base/LitTextarea.vue';
import QuestionnaireRadioGroup from '@/js/components/QuestionnaireRadioGroup.vue';

defineProps<{
  questions: Question[]
  isQuestionVisible: (id: number) => boolean
}>();

const emit = defineEmits<{
  'update:answer': [question: Question, answer: string | string[] | null]
  'update:openAnswer': [question: Question, answer: string | number]
}>();

const handleAnswerUpdate = (question: Question, answer: string | string[] | null) => {
  emit('update:answer', question, answer);
};

const handleOpenAnswerUpdate = (question: Question, answer: string | number) => {
  emit('update:openAnswer', question, answer);
};

const shouldDisableOption = (question: Question, option: any) => {
  if (question.type === 'single' || !question.maxSelections) {
    return false;
  }

  if (question.answer?.includes(option.value)) {
    return false;
  }

  return Array.isArray(question.answer)
    && question.answer.length >= question.maxSelections;
};
</script>

<template>
  <div
    v-for="question in questions"
    :key="question.id"
    :dusk="`question${question.id}`"
    :class="{ selected: question.answer !== null }"
    class="question mb-20"
  >
    <template v-if="isQuestionVisible(question.id)">
      <div class="question__header">
        <div>
          <span class="question__count">
            {{ question.id }}.
          </span>

          <span class="question__label">
            {{ question.label }}
          </span>
        </div>
      </div>

      <template v-if="question.type === 'single'">
        <slot name="singleOption" :question :update-answer="handleAnswerUpdate">
          <div>
            <template v-if="question.viewStyle && question.viewStyle === 'radioGroup'">
              <QuestionnaireRadioGroup
                :model-value="typeof question.answer === 'string' ? question.answer : ''"
                :options="(question.options as any)"
                dusk="question-options"
                @update:model-value="handleAnswerUpdate(question, $event)"
              />
            </template>
            <LitRadio
              v-else
              :model-value="typeof question.answer === 'string' ? question.answer : ''"
              class="mb-20"
              :options="question.options"
              dusk="question-options"
              @update:model-value="handleAnswerUpdate(question, $event)"
            />
          </div>
        </slot>
      </template>

      <div
        v-else
        dusk="question-options"
      >
        <template
          v-for="option in question.options"
          :key="option.value"
        >
          <div
            class="question__option mt-10 mb-10"
          >
            <slot
              name="multipleOption"
              :question
              :option
              :update-answer="handleAnswerUpdate"
              :disabled="shouldDisableOption(question, option)"
            >
              <LitCheckbox
                :label="option.label"
                :model-value="question.answer?.includes(option?.value)"
                :is-disabled="shouldDisableOption(question, option)"
                rounded
                @update:model-value="handleAnswerUpdate(question, option.value)"
              >
                {{ option.label }}
              </LitCheckbox>
            </slot>

            <LitTooltip
              v-if="option.tooltip"
              tooltip-position="top"
            >
              <span v-html="option.tooltip" />
            </LitTooltip>
          </div>

          <template v-if="option.hasOpenAnswer && option.openAnswerType === 'textarea'">
            <LitTextarea
              v-show="question.answer?.includes(option.value)"
              :max-length="option.maxLength || null"
              :model-value="question.openAnswer || ''"
              :rows="2"
              @update:model-value="handleOpenAnswerUpdate(question, $event)"
            />
          </template>
        </template>

        <slot name="additionalContent" :question />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';
@import '@sass/tools/functions';

.question {
  &__header {
    display: flex;
    margin-bottom: 10px;
    font-weight: $bold;
    line-height: 1.45;
    text-align: left;
  }

  &__count {
    flex-shrink: 0;
    margin-right: 15px;
  }

  &__label {
    margin-right: 10px;
    flex-grow: 1;
  }

  &__action {
    flex-shrink: 0;
    justify-self: flex-end;
    font-weight: $normal;
  }

  &__content {
    margin-left: 10px;
  }

  &__option {
    display: flex;
    align-items: center;
  }
}
</style>
