<template>
  <div dusk="recapitulation">
    <JpsStepper current-step="recapitulation" />

    <RecapitulationTemplate
      form-type="smlouvy"
      :error="error"
    >
      <template #recapitulation>
        <RecapitulationDistributor
          namespace="jps"
          :store-path="beforeStart"
        />

        <hr class="my-30">

        <RecapitulationConsents
          namespace="jps"
        >
          <template #marketing>
            {{ consents.marketingPurposes.value === true ? 'Ano' : 'Ne' }}
          </template>
        </RecapitulationConsents>

        <hr class="my-30">

        <RecapitulationContactInformation
          v-if="isParticipant"
          namespace="jps"
          :store-path="contactInformation"
          title="Kontaktní údaje klienta"
        />

        <hr
          v-if="isParticipant"
          class="my-30"
        >

        <RecapitulationPersonalData
          namespace="jps"
          :store-path="personalData"
          :is-participant="isParticipant"
        />

        <hr class="my-30">

        <!-- signer contact information -->
        <RecapitulationContactInformation
          v-if="!isParticipant"
          namespace="jps"
          title="Kontaktní údaje podepisující osoby"
          :store-path="contactInformation"
        />

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <RecapitulationSignerPersonalData
          v-if="!isParticipant"
          namespace="jps"
          :store-path="signer"
        />

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <RecapitulationLegalRequirements
          namespace="jps"
          :store-path="legalRequirements"
          :is-participant="isParticipant"
          :is-signer-politically-exposed="signer.isPoliticallyExposed.value ?? false"
        />

        <hr class="my-30">

        <RecapitulationContributionAndStrategy
          namespace="jps"
          :store-path="contributionAndStrategy"
          :current-contract-store-path="contractSettings.currentContract"
          :transferring-current-contract="true"
          :is-from-request="false"
          :is-from-transfer="false"
          :is-granted-pension="legalRequirements.grantedPension.value"
        />

        <hr class="my-30">

        <RecapitulationContractSettings
          namespace="jps"
          :store-path="contractSettings"
        />

        <hr class="my-30">

        <RecapitulationClientsStatement
          v-if="store.beforeStart.isTiedAgent.value && consents.clientStatement.value !== null && consents.clientStatement.value !== ''"
          namespace="distribution"
        >
          <template #statement>
            {{ consents.clientStatement.value }}
          </template>
        </RecapitulationClientsStatement>

        <hr
          v-if="store.consents.clientStatement.value !== null && store.consents.clientStatement.value !== ''"
          class="my-30"
        >

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="jps"
          field-path="contractSettings.contractSignatureDate"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Datum podepsání dokumentace"
            name="jpsSignatureDate"
            dusk="jps-sign-date"
            disabled
            :model-value="formatDate(value)"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="jps"
          field-path="contractSettings.contractCreationDate"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Datum vzniku penzijního spoření"
            name="new-contract-start-date"
            dusk="contract-start-date"
            disabled
            :model-value="formatDate(value)"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>
      </template>
    </RecapitulationTemplate>

    <div
      :class="['container--sm', 'mt-30', allowAnotherDeviceSignature ? 'mb-90' : 'mb-30']"
    >
      <div class="col-md-6 mb-20">
        <MeetingRecordPreview
          v-if="store.beforeStart.isTiedAgent.value"
        />
      </div>

      <div class="btn-recapitulation flex flex-between">
        <LitButton
          variant="outline"
          :to="{ name: 'jps.clientsStatement' }"
        >
          <template #before>
            <ArrowLeft :size="20" />
          </template>

          <span>
            Zpět
          </span>
        </LitButton>

        <div v-if="isRedirected">
          <LitButton
            class="text-uppercase"
            dusk="digital-submit-step-redirection"
            @click="submitStep('digital')"
          >
            <span>
              Podepsat elektronicky s&nbsp;klientem
            </span>
          </LitButton>
        </div>

        <LitButton
          v-else
          dusk="lit-modal"
          @click="showLitModal"
        >
          <span>
            POKRAČOVAT
          </span>

          <template #after>
            <ArrowRight :size="20" />
          </template>
        </LitButton>
      </div>

      <LitModal
        class="max-w-475 m-auto"
        close-button-color="#092f68"
        :model-value="stepModalVisibility"
        @update:model-value="stepModalVisible = false"
      >
        <template #header>
          <div class="flex flex-center">
            <IconWrapper type="info">
              <LitIconSvg
                icon-name="clipboard-list-check"
                class="header-icon__svg"
              />
            </IconWrapper>
          </div>
          <div>
            <h3 class="text-center">
              Podpis dokumentace bude probíhat následovně:
            </h3>
          </div>
        </template>

        <template #body>
          <div class="stepper-vertical">
            <div
              id="questionnaire"
              class="step__header"
            >
              <div class="step__icon">
                <span class="step__icon--number">
                  1
                </span>
              </div>

              <div class="step__label">
                Podpis nové smlouvy
              </div>
            </div>

            <div class="step__container step__content">
              <p>
                Nejprve je potřeba podepsat smlouvu k novému DPS.
              </p>

              <div class="w-100 dropdown-btn">
                <LitButton
                  class="text-uppercase"
                  @click="showDigitalSignModal = true"
                >
                  Podepsat elektronicky
                </LitButton>
              </div>
            </div>

            <hr class="separator my-20">

            <div class="step" disabled>
              <div
                id="questionnaire"
                class="step__header"
              >
                <div class="step__icon unchecked-step">
                  <span class="step__icon--number">
                    2
                  </span>
                </div>

                <div class="step__label">
                  Podpis výpovědi stávající smlouvy s Bank iD
                </div>
                <br>
              </div>
              <div class="step__container step__content">
                <p>
                  Po podpisu smlouvy k DPS klientovi <strong>přijde e-mail
                    s odkazem na Bank iD</strong>,
                  prostřednictvím
                  kterého klient podepíše výpověď s žádostí o převod
                  současného penzijka k ČSOB Penzijní společnosti.
                </p>

                <LitAlert
                  alert-type="info"
                >
                  <strong>Podpis v odkazu lze dokončit do půlnoci dnešního dne.</strong> Pokud nedojde k úspěšnému podpisu,
                  žádost o převod ani nové DPS nevzniknou.
                </LitAlert>
              </div>
            </div>
          </div>
        </template>
      </LitModal>

      <ContractSigningModal
        v-model="showDigitalSignModal"
        @sign-with-phone="sendDraftToken('digital')"
        @sign-later="sendDraftToken('digital')"
        @sign-with-computer="submitStep('digital')"
      />

      <DateCorrectionModal
        form-type="jps"
        :model-value="dateCorrectionModal"
        @click="closeDateCorrectionModal"
      />

      <ContractSubmissionModal
        type="jps"
        :model-value="redirecting"
        :is-digital="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  add,
  addMonths,
  format,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { ArrowLeft, ArrowRight } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { draftTokens, signatures } from '@/js/api';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

import LitInput from '@/js/components/Base/LitInput.vue';
import LitModal from '@/js/components/Base/LitModal.vue';
import IconWrapper from '@/js/components/IconWrapper.vue';
import MeetingRecordPreview from '@/js/components/MeetingRecordPreview.vue';
import ContractSigningModal from '@/js/components/Modals/ContractSigningModal.vue';
import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import DateCorrectionModal from '@/js/components/Modals/DateCorrectionModal.vue';
import JpsStepper from '@/js/components/Steppers/JpsStepper.vue';

import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat';

import { useJpsStore } from '@/js/stores';
import { submitContract } from '@/js/stores/utils';
import { scrollToError } from '@/js/utils';
import RecapitulationClientsStatement from '@/js/views/common/recapitulation/ClientsStatement.vue';
import RecapitulationConsents from '@/js/views/common/recapitulation/Consents.vue';
import RecapitulationContactInformation from '@/js/views/common/recapitulation/ContactInformation.vue';
import RecapitulationContractSettings from '@/js/views/common/recapitulation/ContractSettings.vue';
import RecapitulationContributionAndStrategy from '@/js/views/common/recapitulation/ContributionAndStrategy.vue';
import RecapitulationDistributor from '@/js/views/common/recapitulation/Distributor.vue';

import RecapitulationLegalRequirements from '@/js/views/common/recapitulation/LegalRequirements.vue';
import RecapitulationPersonalData from '@/js/views/common/recapitulation/PersonalData.vue';
import RecapitulationTemplate from '@/js/views/common/recapitulation/RecapitulationTemplate.vue';
import RecapitulationSignerPersonalData from '@/js/views/common/recapitulation/SignerPersonalData.vue';

const route = useRoute();
const router = useRouter();

const store = useJpsStore();
const {
  contractSettings,
  isParticipant,
  consents,
  contributionAndStrategy,
  personalData,
  signer,
  legalRequirements,
  contactInformation,
  beforeStart,
  draftToken,
  submitResponse,
} = storeToRefs(store);
const { validateField, validateFields } = store;

const fields = shallowRef([
  'contractSettings.contractCreationDate',
  'contractSettings.contractSignatureDate',
]);

const submitting = ref(false);
const validating = ref(false);
const error = ref(false);
const showPrintConfirmationModal = ref(false);
const allowAnotherDeviceSignature = ref(false);
const showDigitalSignModal = ref(false);
const signingLink = ref(null);
const redirecting = ref(false);
const isRedirected = ref(false);
const stepModalVisible = ref(false);
const dateCorrectionModal = ref(false);

const expectedContractEndDate = computed(() => {
  const addedMonths = contractSettings.value.currentContract.type.value === 'transformFund' ? 2 : 1;
  const expectedMinDate = addMonths(new Date(contractSettings.value.contractSignatureDate.value as string), addedMonths);

  return lastDayOfMonth(expectedMinDate);
});

const stepModalVisibility = computed(() => {
  return !(showDigitalSignModal.value || !stepModalVisible.value || redirecting.value);
});

function closeDateCorrectionModal () {
  dateCorrectionModal.value = false;
  const expectedDate = useDateTimeFormat(expectedContractEndDate.value, 'yyyy-MM-dd');

  contractSettings.value.currentContract.contractEndsAt.value = expectedDate;

  setStartDateOfNewPensionSavings();
}

function setStartDateOfNewPensionSavings () {
  const currentContractEndDate = new Date(
    contractSettings.value.currentContract.contractEndsAt.value as string,
  );

  const startDate = startOfMonth(add(new Date(currentContractEndDate), {
    months: 1,
  }));

  contractSettings.value.contractCreationDate.value = format(startDate, 'yyyy-MM-dd');
}

function areDatesValid () {
  return useDateTimeFormat(expectedContractEndDate.value, 'yyyy-MM-dd') === contractSettings.value.currentContract.contractEndsAt.value;
}

async function submitStep (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;
    showPrintConfirmationModal.value = false;
    showDigitalSignModal.value = false;

    const isDigital = signMethod === 'digital';

    if (isDigital) {
      redirecting.value = true;
    } else {
      submitting.value = true;
    }

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), true, false);

    await submitContract(store.$state, {
      contractUuid: contractUuid as string,
      throwOnErrors: true,
      signature,
    });

    if (isDigital) {
      // @ts-expect-error: TODO: fix this
      signingLink.value = submitResponse.value.value?.metadata.signingLinks[0].value;

      window.location.href = signingLink.value || '';
    } else {
      await router.push({
        name: 'jps.thankYouPage',
        params: {
          contractUuid: route.params.contractUuid,
        },
      });
    }
  } catch (e: any) {
    error.value = true;
    submitting.value = false;
    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

function setContractSignatureDate () {
  contractSettings.value.contractSignatureDate.value = useDateTimeFormat(new Date(), 'yyyy-MM-dd');
}

function showLitModal () {
  stepModalVisible.value = true;
}

function formatDate (date: string | null) {
  if (!date) {
    return '';
  }

  const dateToFormat = new Date(date);
  return useDateTimeFormat(dateToFormat, 'dd. MM. yyyy');
}

async function sendDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    const { data } = await draftTokens.create(contractUuid as string, signMethod);

    draftToken.value.value.value = data.draftToken;
    draftToken.value.value.expiresAt = data.validTo;

    await router.push({
      name: 'jps.thankYouSignaturePage',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

onMounted(() => {
  setContractSignatureDate();

  setStartDateOfNewPensionSavings();

  if (route.query.draftToken) {
    isRedirected.value = true;
  }

  if (route.query.draftToken && !areDatesValid()) {
    dateCorrectionModal.value = true;
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';
@import '@sass/tools/functions';

@include media(max, $xs) {
  .btn-recapitulation {
    flex-direction: column-reverse;
    gap: 16px;
  }
}

@include media-between($xs, $sm) {
  .lit-btn {
    font-size: 14px;
  }
}

.stepper-vertical {
  .step {
    &__header {
      font-family: $fontSecondary;
      font-size: 21px;
      display: flex;
      align-items: center;
      padding: 0 0 18px;

      @include media(min, $sm) {
        font-size: 22px;
      }
    }

    &__icon {
      border-radius: 50%;
      height: 23px;
      width: 23px;
      flex-shrink: 0;
      font-size: $root-mini;
      background: getColor(default);
      color: getColor(white);
      margin-right: 10px;
      position: relative;

      @include media(min, $sm) {
        height: 41px;
        width: 41px;
        font-size: 21px;
      }

      &--number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        height: fit-content;
        margin-top: -1px;
      }
    }

    &__container {
      margin-left: 11.5px;
      border: 0;
      position: relative;

      @include media(min, $sm) {
        margin-left: 20.5px;
      }

      [class*='alert-'] {
        min-height: 55px;
      }

      .underlined {
        text-decoration: underline;

        .rotated {
          transform: rotate(180deg);
        }
      }
    }

    &:not(:last-of-type) {
      .step__container {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          border-left: 1px solid getColor(default);
          top: -6px;
          bottom: -6px;

          @include media(min, $sm) {
            left: -1px;
            border-left-width: 2px;
          }
        }
      }
    }

    &__content {
      padding: 5px 0 5px 30.5px;
    }

    &[disabled] {
      .step__icon {
        background: getColor(light-blue);
        color: getColor(default);
      }
    }
  }
}
</style>
