<template>
  <div class="body">
    <TheHeader>
      <template #heading>
        <h1><strong>Generování čísla smlouvy</strong></h1>
      </template>
    </TheHeader>

    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm" />
    </div>

    <main>
      <router-view />
    </main>

    <TheFooter />
  </div>
</template>

<script lang="ts">
import TheFooter from '../TheFooter.vue';
import TheHeader from '../TheHeader.vue';
import env from '@/js/env.ts';

export default {
  components: {
    TheHeader,
    TheFooter,
  },

  beforeMount () {
    const isAllowed = env.ALLOW_CONTRACT_NUMBER_GENERATION;

    if (!isAllowed) {
      this.$router.push({ name: 'notFound' });
    }
  },
};
</script>
