import { computed, ref, toRef } from 'vue';
import type { QuestionnaireAnswer } from '../stores/createStoreFields';
import type { StoreField } from '@/js/composables/useStoreField';
import type {
  MultipleChoiceQuestion,
  PensionQuestion,
  Question,
  SingleChoiceQuestion,
} from '@/js/types/questionnaire';
import type { Category } from '../utils/matrix';

import {
  getMatrixResult,
  QUESTION_10_MATRIX,
  QUESTION_89_MATRIX,
} from '../utils/matrix';

interface AnswerHandlingResult {
  answer: string | string[] | null
  category: string | null | undefined
}

interface QuestionnaireField {
  answer: StoreField<QuestionnaireAnswer>
  openAnswer: StoreField<string | null>
  category: StoreField<string | null>
}

export function useQuestionnaire<T extends Record<`question${number}`, QuestionnaireField>> (
  q: PensionQuestion[],
  answers: T,
) {
  const internalQuestions = toRef(q);
  const loading = ref(false);

  const questionFieldsMap = computed(() => {
    return internalQuestions.value.reduce((acc, question) => {
      const questionKey = `question${question.id}` as keyof T;
      acc[question.id] = answers[questionKey] as QuestionnaireField;
      return acc;
    }, {} as Record<number, QuestionnaireField>);
  });

  const questions = computed(() => {
    const fields = questionFieldsMap.value;
    return internalQuestions.value.map((question) => ({
      ...question,
      answer: fields[question.id].answer.value ?? null,
      openAnswer: fields[question.id].openAnswer.value ?? null,
      category: fields[question.id].category.value ?? null,
    }));
  });

  const lastAnsweredQuestion = computed(() => {
    let lastQuestion = 0;

    questions.value.forEach((question) => {
      if (question.answer !== null) {
        lastQuestion = question.id;
      }
    });

    return lastQuestion;
  });

  const calculateMultipleChoicePoints = (question: MultipleChoiceQuestion): number => {
    if (!question.answer) {
      return 0;
    }

    const answerIds = question.answer as string[];
    const optionsMap = new Map(
      question.options.map((opt) => [opt.value, opt.points]),
    );

    // If sumPoints is false, return points from the first answer only
    if (!question.sumPoints) {
      return optionsMap.get(answerIds[0]) || 0;
    }

    // If sumPoints is true, sum up all points
    return answerIds.reduce((total, answerId) =>
      total + (optionsMap.get(answerId) || 0), 0);
  };

  const calculateSingleQuestionPoints = (question: SingleChoiceQuestion): {
    points: number
    isKo: boolean
  } => {
    if (!question.answer) {
      return {
        points: 0,
        isKo: false,
      };
    }

    const answerId = question.answer as string;

    if (answerId === question.koAnswer) {
      return { points: 0, isKo: true };
    }

    const optionsMap = new Map(
      question.options.map((opt) => [opt.value, opt.points]),
    );

    return {
      points: optionsMap.get(answerId) || 0,
      isKo: false,
    };
  };

  const forceKoAfterQuestion2 = ref(false);

  const status = computed(() => {
    let totalPoints = 0;
    let isKoFound = false;
    let isKoFromQuestion2 = false;

    const unansweredQuestions = questions.value.reduce((count, question) => {
      if (!question.answer) {
        return count + 1;
      }

      // Check for regular KOs first
      if (question.type === 'multiple') {
        totalPoints += calculateMultipleChoicePoints(question as Question & { type: 'multiple' });
      } else {
        const result = calculateSingleQuestionPoints(question as Question & { type: 'single' });
        if (result.isKo) {
          isKoFound = true;
          return count;
        }

        totalPoints += result.points;
      }

      // Only check for KO from question 2 if we don't have a regular KO
      if (question.id === 2 && forceKoAfterQuestion2.value && !isKoFound) {
        isKoFromQuestion2 = true;
        return count;
      }

      return count;
    }, 0);

    // Return appropriate status based on KO type
    if (isKoFound) {
      return {
        totalPoints: 0,
        unansweredQuestions: 3,
        isKo: true,
        isKoFromQuestion2: false,
      };
    }

    if (isKoFromQuestion2) {
      return {
        totalPoints: 0,
        unansweredQuestions: 3,
        isKo: true,
        isKoFromQuestion2: true,
      };
    }

    return {
      totalPoints,
      unansweredQuestions,
      isKo: false,
      isKoFromQuestion2: false,
    };
  });

  const resetAnswer = (questionId: number, cb: (questionId: number, answer: QuestionnaireAnswer) => void) => {
    if (typeof questionId !== 'number' || questionId < 1) {
      throw new Error('Invalid questionId');
    }

    const fieldsToReset = questions.value
      .filter((question) => question.id >= questionId)
      .map((question) => ({
        id: question.id,
        field: answers[`question${question.id}` as keyof T] as QuestionnaireField,
      }));

    fieldsToReset.forEach(({ id }) => {
      cb(id, null);
    });
  };

  const isQuestionVisible = computed(() => (questionId: number) => {
    const INITIAL_TWO_QUESTIONS = 2;

    // When in KO state, show only questions up to lastAnsweredQuestion
    if (status.value.isKo) {
      // Reset answers for questions after KO
      if (questionId > INITIAL_TWO_QUESTIONS) {
        resetAnswer(questionId, (questionId, _answer) => {
          const questionKey = `question${questionId}` as keyof T;
          const field = answers[questionKey] as QuestionnaireField;

          field.answer.value = null;
          field.openAnswer.value = '';
          field.category.value = null;
        });
      }

      return questionId <= lastAnsweredQuestion.value;
    }

    // For first two questions, show them progressively
    if (questionId <= INITIAL_TWO_QUESTIONS) {
      return questionId <= lastAnsweredQuestion.value + 1;
    }

    // For questions after 2, show all if first two questions are answered
    return lastAnsweredQuestion.value >= INITIAL_TWO_QUESTIONS;
  });

  function handleSingleChoice (
    question: SingleChoiceQuestion,
    answer: string | null,
  ): AnswerHandlingResult {
    if (!answer) {
      return {
        answer: null,
        category: null,
      };
    }

    const selectedOption = question.options.find((opt) => opt.value === answer);
    return {
      answer,
      category: selectedOption?.category ?? null,
    };
  }

  function handleMultipleChoice (
    question: MultipleChoiceQuestion,
    newAnswer: string,
    currentAnswer: string[] | null,
  ): AnswerHandlingResult {
    // Handle mutually exclusive option
    if (question.mutuallyExclusive === newAnswer) {
      const selectedOption = question.options.find((opt) => opt.value === newAnswer);
      return {
        answer: [newAnswer],
        category: selectedOption?.category ?? null,
      };
    }

    // If selecting other option while mutually exclusive is selected
    if (currentAnswer?.includes(question.mutuallyExclusive ?? '')) {
      const selectedOption = question.options.find((opt) => opt.value === newAnswer);
      return {
        answer: [newAnswer],
        category: selectedOption?.category ?? null,
      };
    }

    // Handle removing an answer
    if (currentAnswer?.includes(newAnswer)) {
      const filteredAnswers = currentAnswer.filter((item) => item !== newAnswer);
      return {
        answer: filteredAnswers.length > 0 ? filteredAnswers : null,
        category: filteredAnswers.length === 0 ? null : undefined, // undefined means don't update category
      };
    }

    // Add new answer respecting maxSelections
    const newAnswers = currentAnswer ? [...currentAnswer, newAnswer] : [newAnswer];
    if (!question.maxSelections || newAnswers.length <= question.maxSelections) {
      const selectedOption = question.options.find((opt) => opt.value === newAnswer);
      return {
        answer: newAnswers,
        category: selectedOption?.category ?? null,
      };
    }

    return {
      answer: currentAnswer,
      category: undefined,
    };
  }

  const processMatrixResults = (
    question8: Question,
    question9: Question,
    question10: Question,
    updateResults: (matrix89Result: Category, matrix10Result: Category) => void,
  ): void => {
    // Early validation of required categories
    if (!question8.category || !question9.category || !question10.category) {
      throw new Error(
        `Missing categories for questions: ${[
          question8.category ? '' : '8',
          question9.category ? '' : '9',
          question10.category ? '' : '10',
        ].filter(Boolean).join(', ')}`,
      );
    }

    const matrix89Result = getMatrixResult(
      QUESTION_89_MATRIX,
      question9.category,
      question8.category,
    );

    if (!matrix89Result) {
      throw new Error('Could not calculate matrix result for questions 8-9');
    }

    const matrix10Result = getMatrixResult(
      QUESTION_10_MATRIX,
      question10.category,
      matrix89Result,
    );

    if (!matrix10Result) {
      throw new Error('Could not calculate matrix result for question 10');
    }

    // Call the callback with the results
    updateResults(matrix89Result, matrix10Result);
  };

  function updateQuestionnaireUI () {
    if (status.value.unansweredQuestions === 0 || status.value.isKo) {
      loading.value = true;

      setTimeout(() => {
        loading.value = false;
      }, 1700);
    }
  }

  return {
    questions,
    lastAnsweredQuestion,
    isQuestionVisible,
    status,
    loading,
    updateQuestionnaireUI,
    resetAnswer,
    calculateMultipleChoicePoints,
    calculateSingleQuestionPoints,
    handleSingleChoice,
    handleMultipleChoice,
    processMatrixResults,
    forceKoAfterQuestion2,
  };
}
