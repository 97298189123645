<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useConfirmDialog } from '@vueuse/core';

import LitModal from '@/js/components/Base/LitModal.vue';
import LitButton from '@/js/components/Base/LitButton.vue';

interface Props {
  routeName: string
  isPersonalDataEntryMethodMissing: boolean
}

const props = defineProps<Props>();

const { confirm, onConfirm } = useConfirmDialog();
const router = useRouter();

onConfirm(() => {
  router.push({ name: props.routeName });
});
</script>

<template>
  <LitModal
    :model-value="isPersonalDataEntryMethodMissing"
    :max-width="1024"
  >
    <template #header>
      <h3>Zvolte prosím znovu metodu vyplnění osobních údajů</h3>
    </template>

    <template #body>
      <div class="text-center">
        <p class="pb-5">Zdá se, že se nepodařilo uložit zvolenou metodu vyplnění osobních údajů.</p>
        <p>Prosím, potvrďte metodu kliknutím na jednu z dlaždic. Děkujeme.</p>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-center content-center">
        <LitButton
          size="sm"
          @click="confirm"
        >
          Rozumím
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>
