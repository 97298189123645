<template>
  <div class="stepper-vertical">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.stepper-vertical {
  margin-bottom: 20px;
}
</style>
